import React, { useState, useEffect } from "react";
import { MultiSelect, Divider, Text, Select } from '@mantine/core';


export const PowerplantsFilterComponent = ({
    useStyles,
    filters,
    setFilters,
    data,
    addedTargetToComparisonRef
}) => {

    const { classes } = useStyles({ width: 400 });
    let targetOptions = data ? data['historical'].map((target) => ({ label: target, value: target })) : null 
    const [target, setTarget] = useState(targetOptions ? targetOptions[0].value : null );

    useEffect(() => {
        let copyFilters = { ...filters }
        copyFilters['target'] = target
        setFilters(copyFilters)
    }, [target])

    return (
        <>  
            
            <Select
                size='md'
                data={targetOptions}
                value={target}
                searchable
                onChange={(value) => {
                    addedTargetToComparisonRef.current = {state: false, value: null}
                    setTarget(value)
                }}
                label={'Usina'}

            />
        </>

    )

}