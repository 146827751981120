import React, { useState, useEffect } from "react";
import GenericDataTable from "./GenericDataTable";
import { Text } from '@mantine/core';

export const ThermalPowerPlantSpreadsheet = ({
    useStyles,
    data,
    hasSimulation,
}) => {
    const  [selectedRows, setSelectedRows] = useState({})

    console.log('data inside', data)
   const columns = [
    {
        name: 'Nome',
        key: 'name',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Tipo',
        key: 'SigTipoGeracao',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }

    },
    {
        name: 'Capacidade instalada',
        key: 'capacidade_instalada',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Município',
        key: 'DscMuninicpios',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Fase',
        key: 'DscFaseUsina',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Início da operação',
        key: 'DatEntradaOperacao',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }

    },

    {
        name: 'Fim da vigência',
        key: 'DatFimVigencia',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Início da vigência',
        key: 'DatInicioVigencia',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Potência outorgada',
        key: 'MdaPotenciaOutorgadaKw',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },

    {
        name: 'Potência fiscalizada',
        key: 'MdaPotenciaFiscalizadaKw',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Potência garantia física',
        key: 'MdaGarantiaFisicaKw',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Origem do combustível',
        key: 'DscOrigemCombustivel',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Fonte do combustível',
        key: 'DscFonteCombustivel',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Sub-bacia',
        key: 'DscSubBacia',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Data de geração do conjunto de dados',
        key: 'DatGeracaoConjuntoDados',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
        
    {
        name: 'Proprietário',
        key: 'DscPropriRegimePariticipacao',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },

    {
        name: 'Tipo de outorga',
        key: 'DscTipoOutorga',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Núcleo CEG',
        key: 'IdeNucleoCEG',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Latidute',
        key: 'NumCoordNEmpreendimento',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Longitude',
        key: 'NumCoordEEmpreendimento',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    
    },
    {
        name: 'UF',
        key: 'SigUFPrincipal',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }

    },
    {
        name: 'Geração qualificada',
        key: 'IdcGeracaoQualificada',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
    },
  {
        name: 'Sistema elétrico',
        key: 'sistemaEletrico',
        wrapper: ({ children, data }) => {
            return <Text>{children? children : '-'}</Text>
        }
  }

   ] 
   

   const dataArray = [{...data.meta.meta, name: data.meta.meta.NomEmpreendimento}]

   
    return (
        <>
       {dataArray.length > 0 &&
       <div>
           <GenericDataTable
               columns={columns}
               data={dataArray.filter((item) => item !== undefined)}
               selectedRows={selectedRows}
               stickyFirstColumn
               changeSelectedRows={(rows) => setSelectedRows(rows)}
           />
       </div>
}
        </>
    )
}