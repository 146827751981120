import { Center, Checkbox, createStyles, Text } from '@mantine/core';
import React from 'react';

const useStyles = createStyles((theme, { radioLook }) => ({
    root: {
        width: '21px',
        height: '21px',
        backgroundColor: 'white',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #C8C8CE',
        borderRadius: !radioLook ? '4px' : '50%'
    },

    body: {
        width: '15px',
        height: '15px'
    },

    inner: {
        width: '15px',
        height: '15px'
    },

    input: {
        '&:checked': {
            borderColor: 'transparent',
            backgroundColor: theme.colors.orange[0]
        },
        '&:disabled': {
            borderColor: 'transparent',
            backgroundColor: theme.colors.gray[0]
        },
        width: '15px',
        height: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        borderColor: 'transparent',
        borderRadius: radioLook && '50%'
    },

    icon: {
        display: 'none'
    }
}));

const ContainedCheckBox = ({ radioLook, label, labelProps, ...props }) => {
    const { classes } = useStyles({ radioLook });

    return (
        <Center>
            <Checkbox
                {...props}
                classNames={{
                    root: classes.root,
                    body: classes.body,
                    inner: classes.inner,
                    input: classes.input,
                    icon: classes.icon
                }}
            />
            {label && <Text {...labelProps}>{label}</Text>}
        </Center>
    );
};

export default ContainedCheckBox;
