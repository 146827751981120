import React, { useState, useEffect, useRef } from "react";
import { Divider, Switch, Text, Select, Button, MultiSelect, Image, ScrollArea, Card, SegmentedControl, Tabs } from '@mantine/core';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import moment from "moment/moment";
import GeneralLinePlot from "./GeneralLinePlot";
import Info from "../UI/Info";
import { DownloadPpSeries } from "../UI/DownloadPpSeries";

export const HourlySimulationPlot = ({
    useStyles,
    data,
    hasSimulation,
}) => {
    const { classes } = useStyles();
    const [renderChart, setRenderChart] = useState(true)
    const echartsRef = useRef()
    const [instance, setInstance] = useState(null)
    
    useEffect(() => {
        const instance = echartsRef?.current?.getEchartsInstance() 
        if (instance) {
            let copy = {...instance.getOption()}
            setInstance(copy)
        }    
},[echartsRef, renderChart])


    function generateDataInfo(data) {
        let dataInfo = []
        let historicSeries = []
        let mean = []
        let quantile90 = []
        let quantile10 = []
        let min = []
        let max = []
        for (let i = 0; i < data.historical.dates.length; i++) {
            historicSeries.push({ timestamp: data.historical.dates[i], value: data.historical.historical_values[i] })
        }

        dataInfo.push({
            preset: 'historic',
            series: historicSeries,
        })

        if (hasSimulation) {
            for (let i = 0; i < data.simulation.simulation.dates.length; i++) {
                mean.push({ timestamp: data.simulation.simulation.dates[i], value: data.simulation.simulation.mean[i] })
                quantile90.push({ timestamp: data.simulation.simulation.dates[i], value: data.simulation.simulation.quantile_90[i] })
                quantile10.push({ timestamp: data.simulation.simulation.dates[i], value: data.simulation.simulation.quantile_10[i] })
                min.push({ timestamp: data.simulation.simulation.dates[i], value: data.simulation.simulation.min[i] })
                max.push({ timestamp: data.simulation.simulation.dates[i], value: data.simulation.simulation.max[i] })
            }


            dataInfo.push({
                preset: 'mean',
                series: mean,
            })

            dataInfo.push({
                preset: 'max',
                series: max,
            })

            dataInfo.push({
                preset: 'quantile90',
                series: quantile90,
            })

            dataInfo.push({
                preset: 'quantile10',
                series: quantile10,
            })

            dataInfo.push({
                preset: 'min',
                series: min,
            })
        }

        return dataInfo
    }

    const tooltipFormatter = function (params) {
        const tooltipStrings = params
            .map((obj) =>
                obj.value
                    ? `${obj.marker} ${obj.seriesId}: <strong>${obj.value[1]?.toLocaleString(
                        'pt-BR',
                        {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }
                    )}</strong><br />`
                    : ''
            )
            .join('');
        return `${moment(params[0].axisValue).format('lll')}<br />` + tooltipStrings;
    };

    function subtractMonths(date, months) {
        const dateCopy = new Date(date);
        dateCopy.setMonth(dateCopy.getMonth() - months);
        return dateCopy;
    }

    function addMonths(date, months) {
        const dateCopy = new Date(date);
        dateCopy.setMonth(dateCopy.getMonth() + months);
        return dateCopy;
    }

    function formatDate(d) {
        return [d.getFullYear(),
        (d.getMonth() + 1).padLeft(),
        d.getDate().padLeft(),
        ].join('-') + ' ' +
            [d.getHours().padLeft(),
            d.getMinutes().padLeft(),
            d.getSeconds().padLeft()].join(':');
    }
    function generateInicialZoom(data) {
        let currentDate = new Date()
        let initialValue = subtractMonths(currentDate, 1)
        let finalValue = addMonths(currentDate, 1)

        let initialFormatted = formatDate(initialValue)
        let finalFormatted = formatDate(finalValue)


        return { startValue: initialFormatted, endValue: finalFormatted }
    }

    const customYaxis = {
        type: 'value',
        axisLabel: {
            formatter: '{value} MW'
          }
        } 

    const customGrid = {
         containLabel: true,

    }

    const paddingRight =  instance?.grid[0]?.right ? instance.grid[0].right : null
    const paddingLeft = instance?.grid[0]?.left ? instance.grid[0].left : null
    const customToolBox={
        show: true,
        right: instance?.grid[0]?.right ? instance.grid[0].right : '10%',
        feature: {
            dataZoom: {
                show: true,
        }}
    }

    return (
        <>
            <div >

            <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px',marginBottom: 10, justifyContent: 'space-between', paddingRight: paddingRight ? paddingRight : '10%', paddingLeft: paddingLeft ? paddingLeft : '10%' }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                <Switch
                    checked={renderChart}
                    label="Renderizar gráfico"
                    onChange={() => setRenderChart(!renderChart)}

                />
                <Info>
                    Renderizar gráficos com grandes quantidades de dados pode deixar a aplicação lenta. Desligue esta opção para melhorar enquanto navega por outras áreas da ferramenta.
                </Info>
                </div>
                <DownloadPpSeries data={data} useStyles={useStyles} echartsRef={echartsRef}/>
            </div>
                {renderChart ?
                    <GeneralLinePlot
                        customYaxis={customYaxis}
                        customGrid={customGrid}
                        customToolBox={customToolBox}
                        chartsRef={echartsRef}
                        title={''}
                        dataInfo={generateDataInfo(data)}
                        initialZoom={data.source === 'FOTOVOLTAICA' ? generateInicialZoom(data) : null}
                        customTooltipFormatter={tooltipFormatter}
                    /> :
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Text className={classes.CardText}>Ative o switch para visualizar o gráfico</Text>
                    </div>
                }
            </div>
        </>
    )
}

