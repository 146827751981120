import React from "react";
import ReactECharts from "echarts-for-react";
import { registerMap } from "echarts";
import { useEffect, useState } from "react";
import { getMalhaBySIN, getMalha, getAllStates } from "../../apis/ibge";
import * as turf from "@turf/turf";
import { Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DateInput } from "@mantine/dates";
import { Oval } from "react-loader-spinner";
const SINStates = [
  { nome: "SE/CO", states: [31, 32, 33, 35, 50, 51, 52, 53, 11, 12] },
  { nome: "NE", states: [22, 23, 24, 25, 26, 27, 28, 29] },
  { nome: "S", states: [41, 42, 43] },
  { nome: "N", states: [13, 15, 16, 17, 21] },
];

const codeStates = [
  { nome: "Rondônia", id: 11 },
  { nome: "Acre", id: 12 },
  { nome: "Amazonas", id: 13 },
  { nome: "Roraima", id: 14 },
  { nome: "Pará", id: 15 },
  { nome: "Amapá", id: 16 },
  { nome: "Tocantins", id: 17 },
  { nome: "Maranhão", id: 21 },
  { nome: "Piauí", id: 22 },
  { nome: "Ceará", id: 23 },
  { nome: "Rio Grande do Norte", id: 24 },
  { nome: "Paraíba", id: 25 },
  { nome: "Pernambuco", id: 26 },
  { nome: "Alagoas", id: 27 },
  { nome: "Sergipe", id: 28 },
  { nome: "Bahia", id: 29 },
  { nome: "Minas Gerais", id: 31 },
  { nome: "Espírito Santo", id: 32 },
  { nome: "Rio de Janeiro", id: 33 },
  { nome: "São Paulo", id: 35 },
  { nome: "Paraná", id: 41 },
  { nome: "Santa Catarina", id: 42 },
  { nome: "Rio Grande do Sul", id: 43 },
  { nome: "Mato Grosso do Sul", id: 50 },
  { nome: "Mato Grosso", id: 51 },
  { nome: "Goiás", id: 52 },
  { nome: "DF", id: 53 },
];

export default function Map({
  coordenadas,
  setCoordenadas,
  mapType,
  handleClick,
  showFilterComponents,
  loadingMap,
  setLoadingMap,
}) {
  const [mapa, setMapa] = useState({});

  let myChartRef;
  useEffect(() => {
    if (mapType.map !== "SIN") {
      getMalha(
        mapType.map,
        mapType.acronym,
        (data) => {
          if (mapType.map !== "Estado") {
            data.features.forEach((element) => {
              element.properties.name = codeStates.find(
                (item) => item.id == element.properties.codarea
              ).nome;
            });
          }
          setMapa(data);
          setLoadingMap(false);
        },
        (error) => console.log(error)
      );
    } else {
      getMalhaBySIN(
        mapType.acronym,
        (data) => {
          let malha = turf.featureCollection(
            data.features.filter((feature) =>
              SINStates.find(
                (item) => item.nome == mapType.acronym
              ).states.find((code) => feature.properties.codarea == code)
            )
          );
          malha.features.forEach((element) => {
            element.properties.name = codeStates.find(
              (item) => item.id == element.properties.codarea
            ).nome;
          });
          setMapa(malha);
          setLoadingMap(false);
        },
        (error) => console.log(error)
      );
    }
  }, [mapType]);

  function convertToLowerCase (str) {
    let indexFinal = str.indexOf('/')
    if(indexFinal != -1)
      var words = str.slice(0, indexFinal).split(' ')
      words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      return words.join(' ') + str.slice(indexFinal)
  }

  const handleOver = (params) => {
    // if (params.componentType === "geo") {
    //   const clickedX = params.event.offsetX;
    //   const clickedY = params.event.offsetY;
    //   const geoCoord = myChartRef
    //     .getEchartsInstance()
    //     .convertFromPixel("geo", [clickedX, clickedY]);

    //   const newOption = myChartRef.getEchartsInstance().getOption();
    //   newOption.geo[0].tooltip.formatter = (params) => {
    //     if (params.componentType === "geo")
    //       return `Latitude: ${geoCoord[1]}<br>Longitude: ${geoCoord[0]}`;
    //     else if (params.componentType === "series")
    //       return `${params.data.name}<br>Latitude: ${params.data.value[1]}<br>Longitude: ${params.data.value[0]}`;
    //   };

    //   myChartRef.getEchartsInstance().setOption(newOption);
    // }
    if (params.componentType === "series") {
      const newOption = myChartRef.getEchartsInstance().getOption();
      newOption.geo[0].tooltip.formatter = (params) => {
        if (params.componentType === "series")
          if(params.data.conjName)
            return `Nome: ${params.data.name}<br>Conjunto: ${convertToLowerCase(params.data.conjName)}<br>Latitude: ${params.data.value[1]}<br>Longitude: ${params.data.value[0]}`;
          else
              return `Nome: ${params.data.name}<br>Latitude: ${params.data.value[1]}<br>Longitude: ${params.data.value[0]}`;
        else if (params.componentType === "geo") return params.name;
      };

      myChartRef.getEchartsInstance().setOption(newOption);
    }
  };
  const onAddPoint = (newPoint) => {
    setCoordenadas([...coordenadas, newPoint]);
  };
  registerMap("Map1", mapa);
  let option = {
    backgroundColor: "#FFF",
    tooltip: {
      show: true,
      confine: true,
    },
    // label: {
    //   show: true,
    //   position: "right",
    //   formatter: (params) => {
    //     if (params.componentType === "series") return params.data.name;
    //   },
    // },
    toolbox: {
      show: true,
      orient: "vertical",
      left: 10,
      top: "center",
      feature: {
        dataView: { readOnly: false },
        restore: {},
        saveAsImage: {},
      },
    },
    legend: {
      orient: "horizontal",
      bottom: showFilterComponents() ? "0" : "20",
    },
    color: showFilterComponents()
      ? ["#AEAEAE", "#AEAEAE", "#AEAEAE"]
      : ["#fac858", "#5470c6", "#91cc75"],
    series: [
      {
        name: "Solar",
        type: "scatter",
        coordinateSystem: "geo", // Usar coordenadas geográfica
        data: coordenadas
          .filter((coordenada) => coordenada.source == "FOTOVOLTAICA")
          .map((coordenada, index) => ({
            id: coordenada.id,
            name: coordenada.disableName ? "" : coordenada.name,
            value: [
              parseFloat(coordenada.longitude),
              parseFloat(coordenada.latitude),
            ],
            source: coordenada.source,
            state: coordenada.state,
            conjName: coordenada.conjName,
          })),
      },
      {
        name: "Térmica",
        type: "scatter",
        coordinateSystem: "geo", // Usar coordenadas geográfica
        data: coordenadas
          .filter((coordenada) => coordenada.source == "TERMICA")
          .map((coordenada, index) => ({
            id: coordenada.id,
            name: coordenada.disableName ? "" : coordenada.name,
            value: [
              parseFloat(coordenada.longitude),
              parseFloat(coordenada.latitude),
            ],
            source: coordenada.source,
            state: coordenada.state,
            conjName: coordenada.conjName,
          })),
      },
      {
        name: "Eólica",
        type: "scatter",
        coordinateSystem: "geo", // Usar coordenadas geográfica
        data: coordenadas
          .filter((coordenada) => coordenada.source == "EOLIELETRICA")
          .map((coordenada, index) => ({
            id: coordenada.id,
            name: coordenada.disableName ? "" : coordenada.name,
            value: [
              parseFloat(coordenada.longitude),
              parseFloat(coordenada.latitude),
            ],
            source: coordenada.source,
            state: coordenada.state,
            conjName: coordenada.conjName,
          })),
      },
    ],
    geo: {
      map: "Map1",
      roam: !showFilterComponents(),
      nameMap: {},

      label: {
        show: true,
      },
      tooltip: {
        trigger: "item",
      },
    },
  };
  return (
    <>
      {mapa ? (
        <ReactECharts
          option={option}
          style={{ width: "100%", height: "100%" }}
          onEvents={{ click: handleClick, mousemove: handleOver }}
          ref={(e) => (myChartRef = e)}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <Text >Carregando...</Text>
          <Oval
            type="Oval"
            color="rgba(0, 11, 67, 1)"
            secondaryColor="rgba(0, 11, 67, 0.2)"
            height={40}
            width={40}
            style={{ marginTop: 30 }}
          />
        </div>
      )}
    </>
  );
}
