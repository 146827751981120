import React from 'react';
import PropTypes from 'prop-types';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { Box, Tooltip } from '@mantine/core';

const Info = ({
    icon,
    disabled,
    position = 'top',
    multiline = true,
    children,
    iconMarginTop,
    isStandalone,
    style,
    width = null,
    withinPortal
}) => {
    return (
        <Tooltip
            position={position}
            withArrow
            label={children}
            multiline={multiline}
            width={width ? width : 600}
            withinPortal={withinPortal}>
            <Box
                style={{
                    opacity: disabled ? '0.3' : 'none',
                    marginTop: iconMarginTop ? iconMarginTop : 0,
                    marginLeft: isStandalone ? '0px' : '10px',
                    ...style
                }}>
                {icon ? icon : <BsFillInfoCircleFill color="#092c4c" />}
            </Box>
        </Tooltip>
    );
};

Info.propTypes = {
    icon: PropTypes.element,
    disabled: PropTypes.bool,
    position: PropTypes.string,
    multiline: PropTypes.bool,
    children: PropTypes.node,
    iconMarginTop: PropTypes.string,
    isStandalone: PropTypes.bool,
    style: PropTypes.object
};

export default Info;
