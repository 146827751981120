import React, { useState, useEffect, useRef } from "react";
import { Divider, Switch, Text, Select, Button, MultiSelect, Image, ScrollArea, Card, SegmentedControl, Tabs, useMantineTheme } from '@mantine/core';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import moment from "moment/moment";
import GeneralLinePlot from "./plot/GeneralLinePlot";
import Info from "./UI/Info";
import { SolarPowerPlantSpreadsheet } from "./UI/SolarPowerPlantSpreadsheet";
import { ThermalPowerPlantSpreadsheet } from "./UI/ThermalPowerPlantSpreadsheet";
import { HourlySimulationPlot } from "./plot/HourlySimulaitonPlot";
import { AgregatedSomulationPlot } from "./plot/AgregatedSimulationPlot";
import { Oval } from "react-loader-spinner";
import { LampsIndexPlot } from "./plot/LampsIndexPlot";
import { CoverageIndexResultsSection } from "./indexResults/CoverageIndexResultsSection";
import { WppiIndexResultsSection } from "./indexResults/WppiIndexResultsSection";
import { LpgiIndexResultsSection } from "./indexResults/LpgiIndexResultsSection";
export const Results = ({
    useStyles,
    data,
}) => {
    const theme = useMantineTheme()
    const { classes } = useStyles();
    const [visualization, setVisualization] = useState('historicAndForecast')
    const [renderChart, setRenderChart] = useState(false)
    const hasSimulation = data.dataSearched.includes('simulation')
    const [agregation, setAgregation] = useState(hasSimulation ? 'day' : 'hour')

    useEffect(() => {
        setRenderChart(true)
    }, [])

    const generateSelectData = (data) => {
        let optionsArray = []
        optionsArray.push({
            value: 'hour',
            label: 'horária'
        })
        if (hasSimulation && Object.keys(data.aggregated_statistics?.aggregated_statistics).length > 0) {

            if (Object.keys(data.aggregated_statistics.aggregated_statistics).includes('day_historical') && Object.keys(data.aggregated_statistics.aggregated_statistics).includes('day_simulation_statistics')) {
                optionsArray.push({
                    value: 'day',
                    label: 'agregada diária'
                })
            }

            if (Object.keys(data.aggregated_statistics.aggregated_statistics).includes('month_historical') && Object.keys(data.aggregated_statistics.aggregated_statistics).includes('month_simulation_statistics')) {
                optionsArray.push({
                    value: 'month',
                    label: 'argegada mensal'
                })
            }

            if (Object.keys(data.aggregated_statistics.aggregated_statistics).includes('year_historical') && Object.keys(data.aggregated_statistics.aggregated_statistics).includes('year_simulation_statistics')) {
                optionsArray.push({
                    value: 'year',
                    label: 'agregada anual'
                })

            }

        }
        return optionsArray
    }

    return (
        <>

            <div>
                <Card shadow="sm" padding="md" radius="0px" >
                    <div className='titlte-wrapper'>
                        <Text className={classes.CardHeader}>{data.target}</Text>
                    </div>
                    <div className="configs-overview-wrapper" style={{ marginTop: 30, display: 'flex', flexDirection: 'column', gap: 4 }}>

                        <div style={{ display: 'flex' }}>
                            <Text className={classes.LabelText} style={{ width: '22vh' }}>Fonte</Text>
                            <Text className={classes.DataText} style={{ width: '25vh' }}>{data.source}</Text>
                        </div>


                        <div style={{ display: 'flex' }}>
                            <Text className={classes.LabelText} style={{ width: '22vh' }}>Estado</Text>
                            <Text className={classes.DataText} style={{ width: '25vh' }}>{data.state}</Text>
                        </div>


                        <div style={{ display: 'flex' }}>
                            <Text className={classes.LabelText} style={{ width: '22vh' }}>Início do histórico</Text>
                            <Text className={classes.DataText} style={{ width: '50vh' }}>
                                {(data.historical.dates[0] === data.startDate) || hasSimulation ? moment(data.startDate).format('YYYY-MM-DD hh:mm') :  `${moment(data.historical.dates[0]).format('YYYY-MM-DD hh:mm')} (Só há dados apartir dessa data)`}
                            </Text>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Text className={classes.LabelText} style={{ width: '22vh' }}>Fim do histórico</Text>
                            <Text className={classes.DataText} style={{ width: '25vh' }}>{
                                data.dataSearched.includes('historical') ?
                                    data.historical.dates[data.historical.dates.length - 1]?.toString().slice(0, -3) : '-'}</Text>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Text className={classes.LabelText} style={{ width: '22vh' }}>Fim da simulação</Text>
                            <Text className={classes.DataText} style={{ width: '25vh' }}>{hasSimulation ? data.simulation.simulation.dates[data.simulation.simulation.dates.length - 1]?.toString() : '-'}</Text>
                        </div>

                    </div>
                    <div className="visualization-wrapper" style={{ marginTop: 30 }}>
                        <div className="visualization-header-wrapper" style={{ width: '100%' }}>
                            <Tabs value={visualization} onTabChange={setVisualization} keepMounted={true}>
                                <Tabs.List >
                                    <Tabs.Tab disabled={!data.dataSearched.includes('historical')} value="historicAndForecast">{hasSimulation ? "Histórico + simulação" : "Histórico"}</Tabs.Tab>
                                    <Tabs.Tab value="index" disabled={!data.dataSearched.includes('index')}>Índices LAMPS</Tabs.Tab>
                                    <Tabs.Tab value="metas" disabled={!data.dataSearched.includes('meta')}>Metas</Tabs.Tab>
                                </Tabs.List>
                                <Tabs.Panel value="historicAndForecast">
                                    {
                                        data.dataSearched.includes('historical') &&
                                        <>
                                            <div style={{ display: 'flex', alignItems: "center", gap: 3, marginTop: 20, }}>
                                                <Text className={classes.LabelText}>Visualização</Text>
                                                <Select
                                                    data={generateSelectData(data)}
                                                    value={agregation}
                                                    onChange={(value) => setAgregation(value)}
                                                    style={{ width: '25%' }}
                                                    styles={(sx) => ({
                                                        input: {
                                                            fontFamily: theme.other.secundaryFontFamily,
                                                            fontStyle: 'normal',
                                                            fontWeight: 600,
                                                            fontSize: '16px',
                                                            lineHeight: '19px',
                                                            color: theme.colors.gray[9],
                                                            paddingLeft: 5
                                                        },


                                                    })}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 5, marginBottom: 15, marginTop: 5 }}>

                                            <Text className={classes.LabelText}>Status:</Text>
                                            <Text className={classes.DataText}>{hasSimulation ? 'com previsão' : 'sem previsão'}</Text>
                                            </div>
                                            
                                            <Divider style={{ margin: '15px 0px 15px 0px' }} />

                                            <Text className={classes.LabelText}>{hasSimulation ? 'Previsão:' : 'Histórico:'}</Text>
                                            {renderChart &&
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                                                    {
                                                        agregation !== 'hour' && hasSimulation ?

                                                            <AgregatedSomulationPlot
                                                                data={data}
                                                                hasSimulation={hasSimulation}
                                                                useStyles={useStyles}
                                                                agregation={agregation}
                                                            />
                                                            :
                                                            <HourlySimulationPlot
                                                                data={data}
                                                                hasSimulation={hasSimulation}
                                                                useStyles={useStyles}
                                                            />
                                                    }


                                                </div>}
                                        </>
                                    }

                                </Tabs.Panel>
                                <Tabs.Panel value="metas">
                                    {data.dataSearched.includes('meta') && data.source === 'FOTOVOLTAICA' &&
                                        <SolarPowerPlantSpreadsheet
                                            data={data}
                                            hasSimulation={hasSimulation}
                                        />}

                                    {data.dataSearched.includes('meta') && data.source === 'TERMICA' &&
                                        <ThermalPowerPlantSpreadsheet
                                            data={data}
                                            hasSimulation={hasSimulation}
                                        />}
                                </Tabs.Panel>
                                <Tabs.Panel value="index">
                                    <div style={{ marginTop: 30 }}>
                                        {data.index?.index_type === 'Coverage Index' &&
                                        <CoverageIndexResultsSection
                                            data={data}
                                            useStyles={useStyles}
                                        />
                                        }

                                        {
                                            data.index?.index_type === 'Wppi Index' &&
                                            <WppiIndexResultsSection
                                                data={data}
                                                useStyles={useStyles}
                                            />

                                        }

                                        {
                                            data.index?.index_type === 'Lamps Physical Guarantee Index' &&
                                            <LpgiIndexResultsSection
                                                data={data}
                                                useStyles={useStyles}
                                            />

                                        }
                                        
                                                   
                                    </div>
                                </Tabs.Panel>
                            </Tabs>


                        </div>
                    </div>

                </Card>
            </div>
        </>
    )
}

