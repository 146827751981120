import axios from 'axios';


const api = axios.create({
    baseURL: 'https://bsn9itycd6.execute-api.sa-east-1.amazonaws.com/production'
    //baseURL: 'http://127.0.0.1:8000'
});


export const getStates = async (
    type,
    completion,
    errorOcurred,
) => {
    let postDict = {type: type}

    api.post('states/states/', postDict)
    .then((response) => {
        completion(response.data);
    }
    )
    .catch((error) => {
        errorOcurred(error);
    }
    );
}

export const getPowerPlants = async (
    type,
    state,
    completion,
    errorOcurred,
) => {
    let postDict = {type: type, state: state}

    api.post('powerplant/powerplant/', postDict)
    .then((response) => {
        completion(response.data);
    }
    )
    .catch((error) => {
        errorOcurred(error);
    }
    );
}

export const getPowerPlantsData = async (
    state,
    type,
    powerplant,
    request,
    startDate,
    completion,
    errorOcurred,
) => {
    let postDict = {state: state, type: type, powerplant: powerplant, start_date: startDate, request: request}

    api.post('series/series/', postDict)
    .then((response) => {
        completion(response.data);
    })
    .catch((error) => {
        errorOcurred(error);
    }
    );
}

export const getPowerPlantsSeriesCSV = async (
    state,
    type,
    powerplant,
    startDate,
    request,
    completion,
    errorOcurred,
) => {
    let postDict = {state: state, type: type, powerplant: powerplant, start_date: startDate, request: request}

    api.post('series/download/', postDict)
    .then((response) => {
        completion(response.data);
    })
    .catch((error) => {
        errorOcurred(error);
    }
    );
}

export const getAllPowerPlantsNames = async (
    completion,
    errorOcurred
) => {
    let postDict = {}
    api.post('names/names/', postDict)
    .then((response) => {
        completion(response.data); 
    }
    )
    .catch((error) => {
        errorOcurred(error);
    }   
    );
}


export const getIndexes = async (
    queryNames,
    study,
    completion,
    errorOcurred
) => {
    let postDict = {query_names: queryNames, study: study}
    api.post('index/index/', postDict)
    .then((response) => {
        completion(response.data);
    }
    )
    .catch((error) => {
        errorOcurred(error);
    }
    );
}





export default api;