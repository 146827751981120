
import React, { useState, useEffect, useRef } from "react";
import { Navbar, createStyles, Divider, Switch, Text, Select, Button, Stack, Image, ScrollArea, Tabs, Checkbox, TextInput, Group, MultiSelect, Modal } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { getStates, getPowerPlants, getPowerPlantsData, getAllPowerPlantsNames, getIndexes } from "../apis/api";
import logo from '../assets/images/logo.svg';
import { TbSearch, TbZoomReset, TbChevronDown, TbChevronUp } from "react-icons/tb";
import { BsFillGearFill } from "react-icons/bs";
import { StatesFilterComponent } from "./SearchFilterComponents/StatesFilterComponent";
import { PowerplantsFilterComponent } from "./SearchFilterComponents/PowerplantsFilterComponent";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { Oval } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Info from "./UI/Info";
import { LampsIndexModal } from "./UI/LampsIndexModal";
import { getAllStates } from "../apis/ibge";
import Map from "./plot/Map";
import { useDisclosure } from "@mantine/hooks";

const toastOptions = {
    autoClose: 3000,
    hideProgressBar: false,
    pauseOnFocusLoss: false,
    position: toast.POSITION.TOP_RIGHT
};


const useStyles = createStyles((theme, { width }) => ({
    navbar: {
        position: 'fixed',
        left: '0%',
        right: '92.99%',
        top: '0%',
        bottom: '0%',
        height: '100vh',
        width: width,
        backgroundColor: theme.colors.white[0],
        padding: theme.spacing.md,
        boxShadow: '-1px 4px 21px rgba(0, 0, 0, 0.08)',

    },

    floatingButton: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '26px',
        height: '26px',
        borderRadius: '8em',
        backgroundColor: theme.colors.orange[0],
        right: '-15px',
        top: '50px',
        cursor: 'pointer',
        border: `5px solid ${theme.colors.offWhite[0]}`
    },

    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    inputToggle: {
        // O Switch do mantine tem um pequeno checkbox transparente
        // na frente (atrapalha para centralizar), para sumir com ele use
        // codigo abaixo:
        ", input[type='checkbox']": {
            display: 'none'
        },
        ', label': {
            color: theme.colors.blue[0]
        }
    },
    LabelText: {
        fontFamily: theme.fontFamily,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        color: theme.colors.gray[1]
    },
    DataText: {
        fontFamily: theme.other.secundaryFontFamily,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        color: theme.colors.gray[9]
    },
    CardHeader: {
        fontFamily: theme.fontFamily,
        fontWeight: theme.headings.fontWeight,
        fontSize: theme.headings.sizes.h4.fontSize,
        lineHeight: '28px',
        color: theme.colors.blue
    },
    CardText: {
        fontFamily: theme.fontFamily,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        marginRight: '5%',
        color: theme.colors.blue
    },

    tooltip: {
        fontFamily: theme.other.secondaryFontFamily,
        fontSize: '14px',
        fontWeight: 500,
        color: theme.colors.gray[0]
    },

    configWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        margin: '15px 15px 15px 15px',
    },

    transparentDisabled: {
        input: {
            '&:disabled': {
                backgroundColor: 'transparent'
            }
        }
    }
    ,
    greyDisabled: {
        '&[data-disabled]': {
            backgroundColor: '#f5f5f5',
        }


    }
}));



const SearchBar = ({
    searches,
    setSearches,
    mapType,
    setMapType,
    mapPoints,
    setMapPoints,
}) => {
    const [sidebarOpened, setSidebarOpened] = useState(false);
    const { classes } = useStyles({ width: sidebarOpened ? 500 : 80 });
    const [source, setSource] = useState('FOTOVOLTAICA');
    const [granularity, setGranularity] = useState('powerplants');
    const [state, setState] = useState(null);
    const [queryData, setQueryData] = useState(null);
    const [filters, setFilters] = useState(null)
    const [loadingQuery, setLoadingQuery] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingMap, setLoadingMap] = useState(false);
    const [statesOptions, setStatesOptions] = useState(null)
    const [queryType, setQueryType] = useState('map')
    const [allPowerPlantsDict, setPowerPlantsDict] = useState(null)
    const [allStates, setAllStates] = useState(null)
    const [statesByRegion, setStatesByRegion] = useState(null)
    const [selectedPPid, setSelectPPid] = useState(null)
    const [hasSimData, setHasSimData] = useState(null)
    const [advancedDataSearch, setAdvancedDataSearch] = useState(false)
    const [coverage, setCoverage] = useState()
    const [modalName, setModalName] = useState("");
    const [modalConjName, setModalConjName] = useState("");
    const [opened, {close, open}] = useDisclosure();
    const [showMap, setShowMap] = useState(false)
    const indexOptions = [
        { label: 'Lamps Physical Guarantee Index', value: 'Lamps Physical Guarantee Index' },
        { label: 'Wppi Index', value: 'Wppi Index' },
        { label: 'Coverage Index', value: 'Coverage Index' }]
    let auxDict = {}
    for (let index of indexOptions) {
        auxDict[index.value] = false
    }
    const [indexes, setIndexes] = useState(auxDict)
    const [ppsToCompare, setPPsToCompare] = useState([])


    useEffect(() => {
        if (queryData) {
            let target = filters?.target
            if (queryData.historical_simulation.includes(target)) {
                setHasSimData(true)
            }
            else {
                setHasSimData(false)
            }
        }

    }, [filters])

    useEffect(() => {
        getAllStates(null, (data) =>
          setAllStates(
            data.map((state) => state.sigla)),
            (error) => console.log(error)
        );
        if (mapType.acronym !== "" && mapType.map == "Região" && statesByRegion === null) 
          getAllStates(
            mapType.acronym,
            (data) => setStatesByRegion(data.map((state) => state.sigla)),
            (error) => console.log(error)
          );
        if (mapType.acronym !== "" && mapType.map == "SIN" && statesByRegion === null) {
            switch (mapType.acronym) {
              case "SE/CO":
                getAllStates(
                  "SE",
                  (data) => setStatesByRegion(data.map((state) => state.sigla)),
                  (error) => console.log(error)
                );
                getAllStates(
                  "CO",
                  (data) =>
                    setStatesByRegion((prev) => [
                      ...prev,
                      ...data.map((state) => state.sigla),
                      "AC",
                      "RO",
                    ]),
                  (error) => console.log(error)
                );
                break;
              case "NE":
                getAllStates(
                  "NE",
                  (data) =>{
                    let states = data.map((state) => state.sigla)
                    states.splice(states.indexOf("MA"), 1)
                    setStatesByRegion(states)
                },
                  (error) => console.log(error)
                );

                break;
              case "S":
                getAllStates(
                  "S",
                  (data) => setStatesByRegion(data.map((state) => state.sigla)),
                  (error) => console.log(error)
                );
                break;
              case "N":
                getAllStates(
                  "N",
                  (data) =>{
                    let states = data.map((state) => state.sigla)
                    states.push("MA")
                    states.splice(states.indexOf("RR"), 1)
                    states.splice(states.indexOf("AC"), 1)
                    states.splice(states.indexOf("RO"), 1)
                    setStatesByRegion(states)
                  },
                  (error) => console.log(error)
                );
                break;
            }
        }
    }, [mapType, statesByRegion])

    const sourceOptions = [
        { value: 'TERMICA', label: 'Térmica' },
        { value: 'hydro', label: 'Hídrica ', disabled: true },
        { value: 'EOLIELETRICA', label: 'Eólica' },
        { value: 'FOTOVOLTAICA', label: 'Solar' },
        { value: 'nuclear', label: 'Nuclear', disabled: true },
    ]

    const granularityOptions = [
        { label: 'Usinas', value: 'powerplants' },
        { label: 'Estados', value: 'state', disabled: true },
        { label: 'Subsistemas', value: 'subsistem', disabled: true },

    ]

    const delimitations = [
        {label: "SIN", value: ["SE/CO", "NE", "N", "S"]},
        {label: "Região", value: ["N", "NE", "SE", "S", "CO"]},
        {label: "Estado", value: allStates}
    ]

    function handleCloseModal () {
        close()
    }
    function handleClick (params) {
        if(!showFilterComponents())
            if (params.componentType === "series") {
            let key = params.data.id
            setModalName(params.data.name);
            if (params.data.conjName)
                setModalConjName(params.data.conjName);
            else
                setModalConjName("")
            setSelectPPid(key);
            setSource(params.data.source)
            setState(params.data.state)
            setGranularity('powerplantName')
            open();
            }
      };
    function convertStringToFloat (stringNumber) {
        if(stringNumber.startsWith(',')){
            stringNumber = '0' + stringNumber
        }

        return parseFloat(stringNumber.replace(',', '.'))
    }

    function filterPowerPlants (key) {
        if(mapType.map == "País"){
            return true
        }
        else{

            if (mapType.acronym !== "") {
              if (mapType.map == "Estado")
                return allPowerPlantsDict[key].state == mapType.acronym;
              if (mapType.map == "Região" && statesByRegion !== null) {
                return statesByRegion.includes(allPowerPlantsDict[key].state);
              }
              if (mapType.map == "SIN" && statesByRegion !== null) {
                return statesByRegion.includes(allPowerPlantsDict[key].state);
              }
            } else {
              return true;
            }
            
        }
    }

    function handleQueryTypeChange(type) {
        if (type == 'simple' || type == 'map' && allPowerPlantsDict === null) {
            getAllPowerPlantsNames(
                (data) => {
                    let idsDict = {}
                    let res = JSON.parse(data.replace(/\bNaN\b/g, "null"))
                    let ppList = []
                    for (let id in res) {
                        if(res[id].latitude && res[id].longitude)
                            idsDict[id] = { name: res[id].name, source: res[id].gen_type, state: res[id].state, latitude: res[id].latitude, longitude: res[id].longitude}
                        else {
                            idsDict[id] = { name: res[id].name, source: res[id].gen_type, state: res[id].state, 
                                powerplants: Object.keys(res[id]["set powerplants"]).map((key) => {
                                    return {
                                        name: key,
                                        latitude: res[id]["set powerplants"][key].latitude,
                                        longitude: res[id]["set powerplants"][key].longitude,
                                    }
                                })}
                        }
                    }
                    setPowerPlantsDict(idsDict)
                    
                },
                (error) => {
                    console.log(error)
                    toast.error('Erro ao pegar os nomes de todas as usinas', toastOptions)
                }
            )
        }
        if (type === 'simple' ) {
            setGranularity(null)
            setSource(null)
            setState(null)
            setMapType({map: "País", acronym: ""})
            setMapPoints([])
            setStatesByRegion(null)
        }
        else if(type === 'map') {
            setGranularity(null)
            setSource(null)
            setState(null)
            setSelectPPid(null)
        }

        else {
            setMapType({map: "País", acronym: ""})
            setSelectPPid(null)
            setMapPoints([])
            setStatesByRegion(null)
        }

        return
    }

    useEffect(() => {
      if (allPowerPlantsDict != null && mapPoints.length == 0) {
        if (mapType.map == "País") {
            let points = Object.keys(allPowerPlantsDict)
              .filter(
                (key) =>
                allPowerPlantsDict[key].latitude && allPowerPlantsDict[key].longitude
              )
              .filter(
                (key) =>
                  allPowerPlantsDict[key].source == "TERMICA" ||
                  allPowerPlantsDict[key].source == "EOLIELETRICA" ||
                  allPowerPlantsDict[key].source == "FOTOVOLTAICA"
              )
              .map((key) => {
                  return {
                    id: key,
                    name: allPowerPlantsDict[key].name,
                    latitude: convertStringToFloat(
                      allPowerPlantsDict[key].latitude
                    ),
                    longitude: convertStringToFloat(
                      allPowerPlantsDict[key].longitude
                    ),
                    source: allPowerPlantsDict[key].source,
                    state: allPowerPlantsDict[key].state,
                    disableName: false,
                  };
              })
            setMapPoints(points.concat(...Object.keys(allPowerPlantsDict)
            .filter(
              (key) =>
                allPowerPlantsDict[key].powerplants
            )
            .filter(
              (key => 
                  allPowerPlantsDict[key].powerplants.length > 0
              )
            )
            .filter(
              (key) =>
                allPowerPlantsDict[key].source == "TERMICA" ||
                allPowerPlantsDict[key].source == "EOLIELETRICA" ||
                allPowerPlantsDict[key].source == "FOTOVOLTAICA"
            )
            .map((key) => { 
              return (
                  allPowerPlantsDict[key].powerplants.map((pp) => {
                      return {
                          id: key,
                          conjName: allPowerPlantsDict[key].name,
                          name: pp.name,
                          latitude: convertStringToFloat(pp.latitude),
                          longitude: convertStringToFloat(pp.longitude),
                          source: allPowerPlantsDict[key].source,
                          state: allPowerPlantsDict[key].state,
                          disableName: false,
                      }
                  })
              )
              })
            ))        
        }
        if (mapType.map != "País" && mapType.acronym !== "") {
          let points = Object.keys(allPowerPlantsDict)
              .filter(
                (key) =>
                allPowerPlantsDict[key].latitude && allPowerPlantsDict[key].longitude
              )
              .filter(
                (key) =>
                  allPowerPlantsDict[key].source == "TERMICA" ||
                  allPowerPlantsDict[key].source == "EOLIELETRICA" ||
                  allPowerPlantsDict[key].source == "FOTOVOLTAICA"
              )
              .filter(filterPowerPlants)
              .map((key) => {
                  return {
                    id: key,
                    name: allPowerPlantsDict[key].name,
                    latitude: convertStringToFloat(
                      allPowerPlantsDict[key].latitude
                    ),
                    longitude: convertStringToFloat(
                      allPowerPlantsDict[key].longitude
                    ),
                    source: allPowerPlantsDict[key].source,
                    state: allPowerPlantsDict[key].state,
                    disableName: false,
                  };
              })
            setMapPoints(points.concat(...Object.keys(allPowerPlantsDict)
            .filter(
              (key) =>
                allPowerPlantsDict[key].powerplants
            )
            .filter(
              (key => 
                  allPowerPlantsDict[key].powerplants.length > 0
              )
            )
            .filter(
              (key) =>
                allPowerPlantsDict[key].source == "TERMICA" ||
                allPowerPlantsDict[key].source == "EOLIELETRICA" ||
                allPowerPlantsDict[key].source == "FOTOVOLTAICA"
            )
            .filter(filterPowerPlants)
            .map((key) => { 
              return (
                  allPowerPlantsDict[key].powerplants.map((pp) => {
                      return {
                          id: key,
                          conjName: allPowerPlantsDict[key].name,
                          name: pp.name,
                          latitude: convertStringToFloat(pp.latitude),
                          longitude: convertStringToFloat(pp.longitude),
                          source: allPowerPlantsDict[key].source,
                          state: allPowerPlantsDict[key].state,
                          disableName: false,
                      }
                  })
              )
              })
            ))        
        }
      }
    }, [mapPoints, allPowerPlantsDict]);

    useEffect(() => {
         if (allPowerPlantsDict == null) {
            getAllPowerPlantsNames(
                (data) => {
                    let idsDict = {}
                    let res = JSON.parse(data.replace(/\bNaN\b/g, "null"))
                    let ppList = []
                    for (let id in res) {
                        if(res[id].latitude && res[id].longitude)
                            idsDict[id] = { name: res[id].name, source: res[id].gen_type, state: res[id].state, latitude: res[id].latitude, longitude: res[id].longitude}
                        else {
                            idsDict[id] = { name: res[id].name, source: res[id].gen_type, state: res[id].state, 
                                powerplants: Object.keys(res[id]["set powerplants"]).map((key) => {
                                    return {
                                        name: key,
                                        latitude: res[id]["set powerplants"][key].latitude,
                                        longitude: res[id]["set powerplants"][key].longitude,
                                    }
                                })}
                        }
                    }
                    setPowerPlantsDict(idsDict)
                    
                },
                (error) => {
                    console.log(error)
                    toast.error('Erro ao pegar os nomes de todas as usinas', toastOptions)
                }
            )
        }
        
    }, [])



    useEffect(() => {
        if (statesOptions === null) {
            let stateDict = {}
            let promiseList = []
            for (let source of sourceOptions) {
                if (!source.disabled) {
                    promiseList.push(new Promise((resolve, reject) => {
                        getStates(source.value,
                            (data) => {
                                let auxArray = []
                                for (let state of data.states) {
                                    auxArray.push({ label: state, value: state })
                                }
                                stateDict[source.value] = auxArray
                                resolve()
                            },
                            (error) => {
                                reject(error)
                            }
                        )
                    }))
                }

            }
            Promise.all(promiseList).then(() => {
                setStatesOptions(stateDict)
            })
                .catch((error) => {
                    toast.error('Erro ao carregar estados', toastOptions)
                    console.log('error getStates', error)

                })

        }
    }, [])


    function getData(selectedSource, selectedGranularity) {
        switch (selectedGranularity) {
            case 'state':
                let auxArray = []
                statesOptions[selectedSource].forEach((state) => {
                    auxArray.push(state.value)
                })
                setQueryData({ states: auxArray })
                setLoadingQuery(false)
                break;
            case 'powerplants':
                getPowerPlants(selectedSource, state,
                    (data) => {
                        setLoadingQuery(false)
                        setQueryData(data)
                    },
                    (error) => {
                        setLoadingQuery(false)
                        toast.error('Erro ao carregar usinas', toastOptions)
                        console.log(error)
                    })
                break;
            case 'subsistem':
                console.log('subsistem');
                break;

            case 'powerplantName':
                getPowerPlants(selectedSource, state,
                    (data) => {
                        setLoadingQuery(false)
                        setQueryData(data)
                        let currentObj = allPowerPlantsDict[selectedPPid]
                        setSource(currentObj.source)
                        setState(currentObj.state)
                        setLoadingQuery(false)
                        let copyFilters = { ...filters }
                        copyFilters['target'] = currentObj.name
                        setFilters(copyFilters)
                    },
                    (error) => {
                        setLoadingQuery(false)
                        toast.error('Erro ao carregar usinas', toastOptions)
                        console.log(error)
                    })
                
                break;

            default:
                console.log('Caso default do switch na getData()')
        }
    }

    function generateRequest(indexes) {
        //Futuramente vai ter um "hasBacktestData"
        if (hasSimData) {
            return ['simulation', 'historical', 'meta', 'aggregated_statistics']
        }
        else {
            return ['historical', 'meta']
        }
    }

    function hasIndexData() {
        if (indexes['Coverage Index'] == true && coverage == null) {
            return false
        }

        else {
            return Boolean(ppsToCompare.length > 1 && Object.keys(indexes).map((key) => indexes[key]).includes(true))
        }
    }

    function buildIndexStudyRequest(dformat) {
        let study = {}
        if (indexes['Coverage Index'] == true) {
            study['min_coverage'] = coverage
            study['start_date'] = dformat
            study['index'] = 'Coverage Index'
        }

        else if (indexes['Lamps Physical Guarantee Index'] == true) {
            study['start_date'] = dformat
            study['index'] = 'Lamps Physical Guarantee Index'
        }
        else {
            study['start_date'] = dformat
            study['index'] = indexOptions.find((el) => { return indexes[el.value] === true }).value
        }
        return study
    }

    function buildQueryNames() {
        let queryNames = {}
        for (let id of ppsToCompare) {
            let name = allPowerPlantsDict[id].name
            let gen_type = allPowerPlantsDict[id].source
            let state = allPowerPlantsDict[id].state
            queryNames[name] = { gen_type: gen_type, state: state }
        }
        return queryNames
    }

    function filterDate(result) {
        if (result.historical_values && result.dates) {
          let newResult = {};
          let validIndices = result.historical_values
            .map((elemento, indice) => (elemento !== null ? indice : null))
            .filter((indice) => indice !== null);
          result.historical_values.filter((elemento) => elemento !== null);
          newResult.historical_values = result.historical_values.filter(
            (elemento) => elemento !== null
          );
          newResult.dates = result.dates
            .map((elemento, indice) =>
              validIndices.includes(indice) ? elemento : null
            )
            .filter((elemento) => elemento !== null);

          return newResult;
        } else return result;
        
    }

    Number.prototype.padLeft = function (base, chr) {
        var len = (String(base || 10).length - String(this).length) + 1;
        return len > 0 ? new Array(len).join(chr || '0') + this : this;
    }

    function handleSearch() {
        const dataInfo = {}
        const promiseList = []
        const requests = generateRequest(indexes)
        var d = new Date(filters.startDate),
            dformat = [d.getFullYear(),
            (d.getMonth() + 1).padLeft(),
            d.getDate().padLeft(),
            ].join('-') + ' ' +
                [d.getHours().padLeft(),
                d.getMinutes().padLeft(),
                d.getSeconds().padLeft()].join(':');
        setLoadingData(true)
        for (let request of requests) {
            promiseList.push(new Promise((resolve, reject) => {
                getPowerPlantsData(state, source, filters?.target, request, dformat,
                    (data) => {
                        var result = JSON.parse(data.replace(/\bNaN\b/g, "null"));
                        let newResult = filterDate(result)
                        dataInfo[request] = newResult
                        resolve()
                    },
                    (error) => {
                        reject(error)
                    }
                )
            }))
        }

        if (hasIndexData()) {
            let study = buildIndexStudyRequest(dformat)
            let queryNames = buildQueryNames()
            promiseList.push(new Promise((resolve, reject) => {
                getIndexes(queryNames, study,
                    (data) => {
                        var result = JSON.parse(data.replace(/\bNaN\b/g, "null"));
                        dataInfo['index'] = result
                        setCoverage({})
                        resolve()
                    },
                    (error) => {
                        reject(error)
                    }
                )
            }))
        }


        Promise.all(promiseList).then((data) => {
            setLoadingData(false)
            handleCloseModal()
            setSearches([...searches, { source: source, granularity: granularity, state: state, ...filters, ...dataInfo, dataSearched: Object.keys(dataInfo) }])
            setQueryData(null)
            setPPsToCompare([])
            setSelectPPid(null)
            addedTargetToComparisonRef.current = { state: false, value: null }
            setState(null)
            setFilters({})
            setIndexes({})
        }
        )
            .catch((error) => {
                setLoadingData(false)
                toast.error('Erro ao carregar dados', toastOptions)
                console.log(error)
            }
            )
    }

    const searchDisabled = Boolean(
        granularity == 'powerplants' && state == null ||
        granularity == null ||
        granularity !== 'powerplantName' && source == null
    )

    const confirmDisabled = Boolean(
        filters == null ||
        filters?.target == null ||
        filters.startDate == null)


    function showFilterComponents() {
        return Boolean(queryData !== null)
    }

    const addedTargetToComparisonRef = useRef()
    useEffect(() => {
        if (!addedTargetToComparisonRef.current?.state && advancedDataSearch) {
            let targetId = Object.keys(allPowerPlantsDict).filter((key) => {
                return allPowerPlantsDict[key].state === state && allPowerPlantsDict[key].source === source && allPowerPlantsDict[key].name === filters?.target
            })

            if (!ppsToCompare.includes(targetId)) {
                let copy = ppsToCompare
                copy.push(targetId[0])
                setPPsToCompare(copy)
                addedTargetToComparisonRef.current = { state: true, value: targetId[0] }
            }
        }
    }, [advancedDataSearch])

    return (
        <>
            {allPowerPlantsDict &&
                <LampsIndexModal
                    hasIndexData={hasIndexData}
                    indexOptions={indexOptions}
                    coverage={coverage}
                    setCoverage={setCoverage}
                    filters={filters}
                    source={source}
                    state={state}
                    selectedPPid={selectedPPid}
                    useStyles={useStyles}
                    open={advancedDataSearch}
                    setOpen={setAdvancedDataSearch}
                    allPowerPlantsDict={allPowerPlantsDict}
                    ppsToCompare={ppsToCompare}
                    setPPsToCompare={setPPsToCompare}
                    indexes={indexes}
                    setIndexes={setIndexes}
                    addedTargetToComparisonRef={addedTargetToComparisonRef}
                />}
            <Navbar
                position={{ top: 0, left: 0 }}
                className={classes.navbar}
                width={{ base: sidebarOpened ? 500 : 80 }}
                style={{ padding: 0 }}
            >
                {sidebarOpened ? (
                    null
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            flexGrow: '1',
                            alignItems: 'start',
                            flexWrap: 'wrap',
                            margin: 15
                        }}>
                        <Stack align="flex-start" justify="space-around" w={'100%'}>
                            <Link to={'/'}>
                                <Image
                                    height={63}
                                    src={'/logo.png'}
                                    alt="LampsCo Logo"
                                    withPlaceholder
                                />
                            </Link>
                            
                        </Stack>
                    </div>
                )}


                {sidebarOpened ? <div className='top-search-section' style={{
                    boxShadow: '0 1px 2px rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15)',
                    border: 'none',
                    width: '100%',
                    zIndex: 2,
                    overflow: 'visible',
                }}>

                    {
                        queryType === "map" && 
                        <div className={classes.configWrapper}>
                            <div  style={{ alignItems: 'center', display: 'flex', gap: 4, cursor: (showFilterComponents() || showMap) ? 'not-allowed' : 'pointer' }}
                                onClick={() => {
                                    if (!showFilterComponents() && !showMap){
                                        handleQueryTypeChange('simple')
                                        setQueryType('simple')}
                                }
                                }>
                                <TbSearch size={12} />
                                <Text className={classes.CardText} style={{ fontSize: 14 }}>
                                    Buscar por nome
                                </Text>
                                
                            </div>
                            <div  style={{ alignItems: 'center', display: 'flex', gap: 4, cursor: (showFilterComponents() || showMap) ? 'not-allowed' : 'pointer' }}
                                onClick={() => {
                                    if (!showFilterComponents() && !showMap){
                                        handleQueryTypeChange('advanced')
                                        setQueryType('advanced')}
                                }
                                }>
                                <TbSearch size={12} />
                                <Text className={classes.CardText} style={{ fontSize: 14 }}>
                                    Busca avançada
                                </Text>
                            </div>
                            
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                               
                                {allPowerPlantsDict && Object.keys(allPowerPlantsDict)?.length > 0 ?
                                <div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                                <Select
                                    size="md"
                                    disabled={showMap}
                                    label='Tipo de mapa'
                                    data={["País", "Região", "Estado", "SIN"]}
                                    value={mapType.map}
                                    onChange={(value) => {
                                        setShowMap(false)
                                        setMapType({ map: value, acronym: ""})
                                        setMapPoints([])
                                        setSelectPPid(null)
                                    }}
                                    className={classes.transparentDisabled}
                                    />
                                {mapType.map !== "País" ?
                               
                                 <Select
                                    size="md"
                                    searchable
                                    disabled={showMap}
                                    label='Delimitação'
                                    data={delimitations.find((item) => item.label == mapType.map).value}
                                    value={mapType.acronym}
                                    onChange={(value) => {
                                        setShowMap(false)
                                        setMapType({...mapType, acronym: value})
                                        setMapPoints([])
                                        setSelectPPid(null)
                                        setStatesByRegion(null)
    
                                    }}
                                    className={classes.transparentDisabled}
                                 /> : <></>}
                                 
                                </div>
                                {/* <Select
                                label='Usina'
                                searchable
                                data={
                                    Object.keys(allPowerPlantsDict).filter((key) => allPowerPlantsDict[key].latitude).filter(filterPowerPlants).map((key) => {     
                                            return { label: allPowerPlantsDict[key].name, value: key, group: allPowerPlantsDict[key].state }
                                })}
                                value={selectedPPid}
                                onChange={(value) => {  
                                    let currentObj = allPowerPlantsDict[value]
                                    setSource(currentObj.source)
                                    setState(currentObj.state)
                                    setGranularity('powerplantName')
                                    setSelectPPid(value)
                                }}
                            />  */}
                            </div>
                                 :
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 30

                                    }}>
                                    <Text className={classes.CardText}>Carregando...</Text>
                                    <Oval
                                        type="Oval"
                                        color="rgba(0, 11, 67, 1)"
                                        secondaryColor="rgba(0, 11, 67, 0.2)"
                                        height={40}
                                        width={40}
                                        style={{ marginTop: 30 }}
                                    />
                                </div>
                            }
                            </div>
                        </div>
                    }

                    {
                        queryType === "simple" &&
                        <div className={classes.configWrapper} >
                            <div  style={{ alignItems: 'center', display: 'flex', gap: 4, cursor: showFilterComponents() ? 'not-allowed' : 'pointer' }}
                                onClick={() => {
                                    if (!showFilterComponents()){
                                        handleQueryTypeChange('advanced')
                                        setQueryType('advanced')}
                                }
                                }>
                                    <TbSearch size={12} />
                                <Text className={classes.CardText} style={{ fontSize: 14 }}>
                                    Busca avançada
                                </Text>
                            </div>
                            <div style={{ alignItems: 'center', display: 'flex', gap: 4, cursor: showFilterComponents() ? 'not-allowed' : 'pointer' }}

                                onClick={() => {
                                    if (!(showFilterComponents())) {
                                        handleQueryTypeChange('map')
                                        setQueryType('map')
                                    }
                                }
                                }
                            >
                                <TbSearch size={12} />
                                <Text className={classes.CardText} style={{ fontSize: 14 }}>
                                    Buscar por Mapa
                                </Text>

                            </div>
                            {allPowerPlantsDict && Object.keys(allPowerPlantsDict)?.length > 0 ?
                                <Select
                                    label='Usina'
                                    searchable
                                    data={Object.keys(allPowerPlantsDict).map((key) => {
                                        return { label: allPowerPlantsDict[key].name, value: key, group: allPowerPlantsDict[key].state }
                                    })}
                                    value={selectedPPid}
                                    onChange={(value) => {
                                        let currentObj = allPowerPlantsDict[value]
                                        setSource(currentObj.source)
                                        setState(currentObj.state)
                                        setGranularity('powerplantName')
                                        setSelectPPid(value)
                                    }}
                                /> :
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 30

                                    }}>
                                    <Text className={classes.CardText}>Carregando...</Text>
                                    <Oval
                                        type="Oval"
                                        color="rgba(0, 11, 67, 1)"
                                        secondaryColor="rgba(0, 11, 67, 0.2)"
                                        height={40}
                                        width={40}
                                        style={{ marginTop: 30 }}
                                    />
                                </div>
                            }
                        </div>

                    }

                    {queryType === "advanced" &&
                        <div className={classes.configWrapper} >
                            <div style={{ alignItems: 'center', display: 'flex', gap: 4, cursor: showFilterComponents() ? 'not-allowed' : 'pointer' }}

                                onClick={() => {
                                    if (!(showFilterComponents())) {
                                        handleQueryTypeChange('simple')
                                        setQueryType('simple')
                                    }
                                }
                                }
                            >
                                <TbSearch size={12} />
                                <Text className={classes.CardText} style={{ fontSize: 14 }}>
                                    Buscar por nome
                                </Text>

                            </div>
                            <div style={{ alignItems: 'center', display: 'flex', gap: 4, cursor: showFilterComponents() ? 'not-allowed' : 'pointer' }}

                                onClick={() => {
                                    if (!(showFilterComponents())) {
                                        handleQueryTypeChange('map')
                                        setQueryType('map')
                                    }
                                }
                                }
                            >
                                <TbSearch size={12} />
                                <Text className={classes.CardText} style={{ fontSize: 14 }}>
                                    Buscar por Mapa
                                </Text>

                            </div>

                            <Select
                                searchable
                                size='md'
                                data={sourceOptions}
                                value={source}
                                onChange={(value) => setSource(value)}
                                disabled={showFilterComponents()}
                                label='Fonte'
                                className={classes.transparentDisabled}
                            />
                            <Select
                                searchable
                                size='md'
                                data={granularityOptions}
                                value={granularity}
                                onChange={(value) => setGranularity(value)}
                                disabled={showFilterComponents()}
                                label='Granularidade'
                                className={classes.transparentDisabled}
                            />

                            {granularity == 'powerplants' &&
                                <Select
                                    searchable
                                    size='md'
                                    data={statesOptions ? statesOptions[source] : []}
                                    value={state}
                                    onChange={(value) => {
                                        setState(value)
                                    }

                                    }
                                    disabled={showFilterComponents()}
                                    label='Estado'
                                    className={classes.transparentDisabled}
                                />
                            }
                        </div>}


                    <div className={classes.configWrapper}>
                        <div style={{ display: 'flex',flexDirection: 'row' , justifyContent: (showFilterComponents() || showMap) ? 'space-between' : 'flex-end', marginBottom: 15 }}>
                        {queryType === "map" ?     
                            <>{!showMap &&                       
                             (<Button 
                                disabled={mapType.map !== 'País' && mapType.acronym === ""}
                                style={{ zIndex: 2 }} 
                                className={classes.greyDisabled} onClick={() => {
                                    setShowMap(true)
                                    setLoadingMap(true)
                             }}
                             variant="outline"

                             >Buscar Mapa</Button>)}
                             </>
                             :
                             <Button
                                style={{ zIndex: 2 }}
                                disabled={searchDisabled}
                                loading={loadingQuery}
                                className={classes.greyDisabled}
                                leftIcon={<TbSearch />}
                                variant="outline"
                                onClick={() => {
                                    setLoadingQuery(true)
                                    getData(source, granularity)
                                }}
                            >
                                Confirmar
                            </Button> }
                            
                            {showFilterComponents() ?
                                <Button
                                    variant="outline"
                                    className={classes.greyDisabled}
                                    style={{ zIndex: 2 }}
                                    leftIcon={<TbZoomReset />}
                                    onClick={() => {
                                        setFilters(null)
                                        setState(null)
                                        setIndexes({})
                                        setSelectPPid(null)
                                        setQueryData(null)
                                        setPPsToCompare([])
                                        
                                        addedTargetToComparisonRef.current = { state: false, value: null }
                                    }
                                    }

                                >
                                    Redefinir
                                </Button>
                                : showMap ?
                                <Button
                                    variant="outline"
                                    className={classes.greyDisabled}
                                    style={{ zIndex: 2 }}
                                    leftIcon={<TbZoomReset />}
                                    onClick={() => {
                                        setShowMap(false)
                                    }
                                    }

                                >
                                    Redefinir mapa
                                </Button> : null
                            }
                          
                            
                        </div>
                    </div>

                </div> :
                    null}
                
                    <div
                        style={{ height: '100%', overflowY: 'auto' , overflowX: 'hidden'}}
                    >

                    {sidebarOpened && showFilterComponents() ?
                        <>
                            <div className={classes.configWrapper} >

                                <Text
                                    className={classes.CardHeader}
                                >Filtros</Text>
                                {queryType == "map" ? 
                                <div style={{display: 'flex', flexDirection: 'column', gap: 1}}>
                                <Text className={classes.LabelText}>Usina: </Text> 
                                <Text className={classes.DataText}>{modalName}</Text>
                                {modalConjName  &&
                                <>
                                <Text className={classes.LabelText}>Conjunto: </Text> 
                                <Text className={classes.DataText}>{modalConjName}</Text>
                                </>
                                }
                                </div>:<></>}
                                {granularity == 'state' &&
                                    <StatesFilterComponent
                                        useStyles={useStyles}
                                        filters={filters}
                                        setFilters={setFilters}
                                        data={queryData?.states}
                                    />
                                }

                                {granularity == 'powerplants' &&
                                    <PowerplantsFilterComponent
                                        addedTargetToComparisonRef={addedTargetToComparisonRef}
                                        useStyles={useStyles}
                                        filters={filters}
                                        setFilters={setFilters}
                                        data={queryData}
                                    />
                                }

                                {
                                    granularity === 'PowerPlantName' &&
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                        <Text className={classes.LabelText} style={{ fontSize: 14 }}>Usina:</Text>
                                        <Text className={classes.DataText} style={{ fontSize: 14 }}>{filters?.target}</Text>
                                    </div>
                                }

                                <DateInput
                                    valueFormat="DD-MM-YYYY"
                                    label="Início do histórico"
                                    placeholder="Date input"
                                    value={filters?.startDate ? new Date(filters.startDate) : null}
                                    styles={(theme) => (
                                        {
                                            calendar:
                                            {
                                                position: 'static'

                                            },
                                            dropdown: {
                                                color: 'pink',
                                                zIndex: 1000
                                            }
                                        }
                                    )}
                                    onChange={(startDate) => {
                                        let copyFilters = { ...filters }
                                        copyFilters['startDate'] = startDate
                                        setFilters(copyFilters)
                                    }}

                                />
                                <div>
                                    <div
                                        style={{ alignItems: 'center', display: 'flex', gap: 4, cursor: 'pointer', marginLeft: -2 }}
                                        onClick={() => {
                                            if (allPowerPlantsDict) {
                                                setAdvancedDataSearch(advancedDataSearch => !advancedDataSearch)
                                            }
                                        }}
                                    >

                                        {allPowerPlantsDict ? <BsFillGearFill size={12} /> : <Oval
                                            type="Oval"
                                            color="rgba(0, 11, 67, 1)"
                                            secondaryColor="rgba(0, 11, 67, 0.2)"
                                            height={14}
                                            width={14}
                                        />}
                                        <Text className={classes.CardText} style={{ fontSize: 14, marginRight: 0, color: allPowerPlantsDict ? null : 'gray' }} >
                                            Indices LAMPS
                                        </Text>

                                        <Info style={{ marginLeft: 5, marginTop: 2 }} width={300}>
                                            O índice é gerado através da comparação de usinas. Selecione as usinas que deseja comparar e quais indices devem ser calculados.
                                        </Info>

                                    </div>
                                    {
                                        hasIndexData() &&
                                        <>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                <Text className={classes.LabelText} style={{ fontSize: 14 }}>Usinas selecionadas:</Text>
                                                <Text className={classes.DataText} style={{ fontSize: 14 }}>{ppsToCompare.length}</Text>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                <Text className={classes.LabelText} style={{ fontSize: 14 }}>Índice:</Text>
                                                <Text className={classes.DataText} style={{ fontSize: 14 }}>{indexOptions.find((el) => {
                                                    return indexes[el.value] === true
                                                }).label}</Text>
                                            </div>
                                        </>

                                    }
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 15 }}>

                                    <Button
                                        variant="outline"
                                        className={classes.greyDisabled}
                                        loading={loadingData}
                                        disabled={confirmDisabled}
                                        onClick={() => {
                                            handleSearch()
                                        }
                                        }
                                    >
                                        Buscar
                                    </Button>
                                </div>

                            </div>
                        </>



                    
                    : null}
                {(queryData === null || filters?.target === null || queryType === "map") && sidebarOpened &&
                    <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {queryType == 'map' ?  
                            <>
                            <Modal  opened={opened} onClose={handleCloseModal} withCloseButton={false} centered >
                            <div className={classes.configWrapper} >
                            <Text className={classes.LabelText} style={{fontSize: 18}}>Você quer pesquisar sobre esta usina?</Text>
                                {modalConjName  &&
                                <>
                                    <Text className={classes.LabelText}>Conjunto: </Text> 
                                    <Text className={classes.DataText}>{modalConjName}</Text>
                                </>
                                }
                                <>
                                    <Text className={classes.LabelText}>Usina: </Text> 
                                    <Text className={classes.DataText}>{modalName}</Text>
                                </>
                                <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-between"}}>
                                    <Button
                                    style={{ zIndex: 2 }}
                                    className={classes.greyDisabled}
                                    variant="outline"
                                    onClick={() => {
                                    handleCloseModal();
                                    }}>
                                        Não
                                    </Button>
                                    <Button
                                    style={{ zIndex: 2 }}
                                    loading={loadingQuery}
                                    className={classes.greyDisabled}
                                    leftIcon={<TbSearch />}
                                    variant="outline"
                                    onClick={() => {
                                        handleCloseModal()
                                        setLoadingQuery(true)
                                        getData(source, granularity)
                                    }}
                                    >
                                        Confirmar
                                    </Button>
                                </div>
                            </div>
                            </Modal>
                            {showMap ? 
                            <Map
                                coordenadas={mapPoints}
                                setCoordenadas={setMapPoints}
                                mapType={mapType}
                                handleClick={handleClick}
                                showFilterComponents={showFilterComponents}
                                loadingMap={loadingMap}
                                setLoadingMap={setLoadingMap}
                            ></Map> 
                                : <Image src={logo} alt="logo" width={290} />}
                                </>: 
                            <Image src={logo} alt="logo" width={290} />}
                    </div>
                }
                </div>
                <div className={classes.floatingButton} onClick={() => setSidebarOpened(!sidebarOpened)}>
                    {sidebarOpened ? <IoChevronBack /> : <IoChevronForward />}
                </div>
                <ToastContainer />
            </Navbar>
        </>

    )
}

export default SearchBar;