import { React, useEffect, useRef, useState } from 'react';
import {
    defaultOptions,
    series_as_list,
    values_as_list,
    jsonDataToCSV,
    ChartTitle
} from './EchartsHelper';
import ReactECharts from 'echarts-for-react';
import * as moment from 'moment';
import { init } from 'echarts';

/*
    Frequently used styles for series
    Use them by setting a 'preset' field inside your series dictionary, in the dataInfo array
*/

const styles = {
    mean: {
        id: 'Média',
        name: 'Média',
        lineStyle: {
            color: 'rgba(254, 121, 56, 1)'
        },
        itemStyle: {
            color: 'rgba(254, 121, 56, 1)'
        }
    },
    fit: {
        id: 'Estimação',
        name: 'Estimação',
        lineStyle: {
            type: 'dashed',
            color: 'rgba(254, 121, 56, 1)'
        },
        itemStyle: {
            color: 'rgba(254, 121, 56, 1)'
        }
    },
    historic: {
        id: 'Histórico',
        name: 'Histórico',
        lineStyle: {
            color: 'rgba(0, 11, 113, 1)'
        },
        itemStyle: {
            color: 'rgba(0, 11, 113, 1)'
        }
    },
    agregatedHistoric: {
        id: 'Histórico agregado',
        name: 'Histórico agregado',
        lineStyle: {
            color: 'rgba(0, 11, 113, 1)'
        },
        itemStyle: {
            color: 'rgba(0, 11, 113, 1)'
        }
    },
    max: {
        id: 'Máximo',
        name: 'Intervalos da simulação',
        z: -5,
        lineStyle: {
            type: 'dashed',
            color: '#ccc'
        },
        itemStyle: {
            color: '#ccc'
        },
        areaStyle: {
            color: '#ccc',
            origin: 'start'
        }
    },
    quantile90: {
        id: 'Quantil 90%',
        name: 'Intervalos da simulação',
        type: 'line',
        z: -4,
        lineStyle: {
            type: 'dashed',
            color: '#aaa'
        },
        itemStyle: {
            color: '#aaa'
        },
        areaStyle: {
            color: '#aaa',
            origin: 'start'
        }
    },
    quantile10: {
        id: 'Quantil 10%',
        name: 'Intervalos da simulação',
        z: -3,
        lineStyle: {
            type: 'dashed',
            color: '#aaa'
        },
        itemStyle: {
            color: '#aaa'
        },
        areaStyle: {
            color: '#ddd',
            origin: 'start'
        }
    },
    min: {
        id: 'Mínimo',
        name: 'Intervalos da simulação',
        z:-2,
        lineStyle: {
            type: 'dashed',
            color: '#ccc'
        },
        itemStyle: {
            color: '#ccc'
        },
        areaStyle: {
            color: 'white',
            origin: 'start',
            opacity: 1
        }
    },
    vegetative: {
        lineStyle: {
            color: 'rgba(166, 34, 27, 1)'
        },
        itemStyle: {
            color: 'rgba(166, 34, 27, 1)'
        }
    },
    index: {
        lineStyle: {
            color: 'RGBA(128, 200, 128, 1)'
        },
        itemStyle: {
            color: 'rgba(0, 160, 0, 1)'
        }
    },

   


};

/*

Componente de plot geral para plots de linha, gráficos mais específicos de linha podem ser feitos como wrapper

* = campo opcional
title: título do gráfico
dataInfo: array com dados de cada série (funciona com saída nativa do series_as_dict)
    [
        {
            name: (nome da série)
            series: (dict da série)
            *preset: (nome de um dos estilos presets da série (presente no dict 'styles' neste arquivo))
            *style: (estilo customizado da série)
            *values: (booleano opcional, caso true vai usar values_as_list em vez de series_as_list)
        },
    ]

*customLegend: [array opcional de legenda customizada]
*customTooltipTrigger: (trigger custom de tooltip)
*customTooltipFormatter: (função customizada de formatter de tooltip)
*customXaxis: (configuração customizada do eixo X)
*customYaxis: (configuração customizada do eixo Y)
*eventListeners: (dicionários com funções que vão ser chamadas para cada evento do eCharts)
    as funções vão receber um 'Echarts Event' como parâmetro, ler documentação ou usar um console.log() para ver o formato da resposta
    formato:
    {
        'click': (função a ser chamada no click),
        'dblclick': (função a ser chamada no click duplo),
        ... etc (eventos disponíveis: https://echarts.apache.org/en/api.html#events)
    }
*noLegend (tira legenda)
*noMargin (tira margem superior do título)
*axisPointerType (string que indica o tipo de visualização do cursor no gráfico. Valores possíveis: 'line', 'shadow', 'none', 'cross'(default))
*customGrid (objeto que indica a posição do grafico dentro do canvas)
*notMerge (se, ao atualizar o gráfico, as linhas antigas vão permanecer ou se vão desaparecer. por padrão desaparecem)
*onEvents (função que é chamada quando ocorrem eventos no gráfico, para que se possa fazer coisas fora do gráfico de acordo com eventos no gráfico)
*chartsRef (uma ref (criada por useRef()) que será associada ao gráfico, para caso seja preciso acessá-la por fora do GeneralLinePlot)
*customBrush ('brushes' customizados para seleção de dados no gráfico)
*visualMap (para utilização do Visual Map no gráfico: https://echarts.apache.org/en/option.html#visualMap)
*inicialZoom (zoom inicial do gráfico. Objeto contendo startValue e endValue (datas))
*/
const GeneralLinePlot = ({
    title = null,
    dataInfo,
    customLegend = null,
    customTooltipTrigger = null,
    customTooltipFormatter = null,
    customXaxis = null,
    customYaxis = null,
    eventListeners = null,
    noLegend = false,
    noMargin = false,
    axisPointerType = null,
    customToolBox = null,
    customGrid = null,
    notMerge = true,
    onEvents = null,
    chartsRef = null,
    customBrush = null,
    visualMap = null,
    initialZoom = null
}) => {

    const series = dataInfo.map((info) => {
        const style = info.style ? info.style : {};
        const preset = info.preset ? styles[info.preset] : {};
        const id = info.id ? info.id : info.name;
        return {
            name: `${info.name}`,
            id: `${id}`,
            show: false,
            data: info?.values ? values_as_list(info.series) : series_as_list(info.series),
            type: 'line',
            showSymbol: false,
            ...preset,
            ...style
        };
    });


    const xAxis = customXaxis ? { xAxis: customXaxis } : {};
    const yAxis = customYaxis ? { yAxis: customYaxis } : {};
    const visualMapDict = visualMap ? { visualMap: visualMap } : {};
    const toolbox = customToolBox ? { toolbox: customToolBox } : {};
    const [grid, setGrid] = useState(
        customGrid
            ? { grid: customGrid }
            : { grid: { left: '5%', top: '15%', right: '5%', bottom: '25%' } }
    );
    const brush = customBrush ? { brush: customBrush } : {};

    const echartsRef = chartsRef 
    useEffect(() => {
        if (echartsRef.current) {
            const instance = echartsRef.current.getEchartsInstance();
            if (eventListeners)
                Object.keys(eventListeners).map((eventType) => {
                    instance.on(eventType, eventListeners[eventType]);
                });
        }
    }, [echartsRef]);

    // function useCanvasWidth() {
    //     useEffect(() => {
    //         function handleResize() {
    //             setCanvasWidth(document.getElementById('canvas-wrapper').clientWidth);
    //         }
    //         window.addEventListener('resize', handleResize);
    //         handleResize();
    //         return () => window.removeEventListener('resize', handleResize);
    //     }, []);
    //     const [canvasWidth, setCanvasWidth] = useState(undefined);

    //     return canvasWidth;
    // }

    // const canvasWidth = useCanvasWidth();
    // useEffect(() => {
    //     let largestYlabelLenght =
    //         Math.round(series[0].data.reduce((a, b) => (a[1] > b[1] ? a : b))[1]).toString()
    //             .length + 1;
    //     let ratio = canvasWidth / largestYlabelLenght;
    //     if (ratio < 40) setGrid({ grid: { left: '25%', top: '15%', right: '25%', bottom: '25%' } });
    //     if (ratio > 40 && ratio < 52)
    //         setGrid({ grid: { left: '20%', top: '15%', right: '20%', bottom: '25%' } });
    //     if (ratio > 52 && ratio <= 100)
    //         setGrid({ grid: { left: '15%', top: '15%', right: '15%', bottom: '25%' } });
    //     if (ratio > 100 && ratio < 200)
    //         setGrid({ grid: { left: '10%', top: '15%', right: '10%', bottom: '25%' } });
    //     if (ratio >= 200 && ratio < 300)
    //         setGrid({ grid: { left: '7.5%', top: '15%', right: '7.5%', bottom: '25%' } });
    // }, [canvasWidth]);

    const opt = {
        ...defaultOptions({ ...dataInfo }),
        ...xAxis,
        ...yAxis,
        ...visualMapDict,
        ...toolbox,
        ...grid,
        ...brush,
        ...{
            renderer: 'canvas',
            dataZoom: [
                {
                    id: 'insideX',
                    type: 'inside',
                    xAxisIndex: [0],
                    filterMode: 'empty',
                    startValue: initialZoom ? initialZoom.startValue : null,
                    endValue: initialZoom ? initialZoom.endValue : null,
                },
                {
                    id: 'sliderX',
                    type: 'slider',
                    xAxisIndex: [0],
                    filterMode: 'empty',
                    startValue: initialZoom ? initialZoom.startValue : null,
                    endValue: initialZoom ? initialZoom.endValue : null,
                
                },
            ],
            series: series,
            legend: noLegend
                ? null
                : {
                      type: 'scroll',
                      orient: 'horizontal',
                      width: '50%',
                      top:0,
                      data: customLegend ? customLegend : series.map((s) => s.name)
                  },
            tooltip: 
           {
                trigger: customTooltipTrigger ? customTooltipTrigger : 'axis',
                axisPointer: {
                    type: axisPointerType ? axisPointerType : 'cross',
                    animation: false,
                    label: {
                        backgroundColor: '#ccc',
                        borderColor: '#aaa',
                        borderWidth: 1,
                        shadowBlur: 0,
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        color: '#222',
                        formatter: function (params) {
                            if (params['axisDimension'] === 'x')
                                return moment(params['value']).format('lll');
                            return params['value']?.toLocaleString('pt-BR', {
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4
                            });
                        }
                    }
                },

                formatter: customTooltipFormatter
                    ? customTooltipFormatter
                    : function (params) {
                          const tooltipStrings = params
                              .map((obj) =>
                                  obj.value
                                      ? `${obj.marker} ${
                                            obj.seriesName
                                        }: <strong>${obj.value[1]?.toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}</strong><br />`
                                      : ''
                              )
                              .join('');
                          return (
                              `${moment(params[0].axisValue).format('lll')}<br />` + tooltipStrings
                          );
                      }
            }
        }
    };
    return (
        <div style={{ width: '100%' }} id={'canvas-wrapper'}>
            {title && <ChartTitle title={`${title}`} noMargin={noMargin} />}
            <ReactECharts
                option={opt}
                notMerge={notMerge}
                ref={echartsRef}
                onEvents={onEvents}
                style={{ height: '300px', width: '100%' }}
                opts={{ renderer: 'canvas', locale: 'PTBR' }}></ReactECharts>
        </div>
    );
};

export default GeneralLinePlot;
