import React, { useState, useEffect } from 'react';
import {
    Table,
    Menu,
    Text,
    createStyles,
    Box,
    ScrollArea,
    Pagination,
    ActionIcon,
    Tooltip,
    Select,
    Divider
} from '@mantine/core';
import { TbChevronDown, TbDots } from 'react-icons/tb';
import {InfinitySpin} from 'react-loader-spinner';
import { useMantineTheme } from '@mantine/core';
import ContainedCheckBox from './ContainedCheckBox';

/**
 * Generate object with CSS classes definitions, to style table.
 *
 */
const useStyles = createStyles((theme, _params, getRef) => ({
    headerCell: {
        transition: 'all 200ms ease',
        userSelect: 'none',
        maxWidth: '200px',
        fontWeight: 500,

        '&:hover': {
            cursor: 'pointer'
        }
    },

    header: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        transition: 'box-shadow 150ms ease',
        zIndex: 3,
        fontWeight: 500,

        '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            borderBottom: `1px solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
            }`
        }
    },

    scrolled: {
        boxShadow: theme.shadows.sm
    },

    stickyColumn: {
        position: 'sticky',
        top: 0,
        transition: 'box-shadow 150ms ease',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        zIndex: 1,
        left: 0
        // '&::after': {
        //     content: '""',
        //     position: 'absolute',
        //     left: 0,
        //     right: 0,
        //     bottom: 0,
        //     borderBottom: `1px solid ${
        //       theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
        //     }`,
        //   },
    },
    noSortingHeaderCell: {
        userSelect: 'none',
        maxWidth: '200px'
    }
}));

/**
 * Generates a sorting function based on passed key and sort orientation (asc/desc).
 * Default sort is always ascending, and reversed in useEffect
 *
 * @param {String} key Column name. This name need to be identical to column key definition, otherwise sorting will not work.
 * @returns {Function} that returns true/false if a[key] greater/less than b[key]. The idea is to use this returned function in sorting algorithms, to sort rows
 */
const generateSort = (key, ascending, customSortFunction = undefined) => {
    const defaultSort = (a, b) =>
        ascending
            ? a[key].localeCompare(b[key], undefined, { numeric: true, sensitivity: 'base' })
            : -a[key].localeCompare(b[key], undefined, { numeric: true, sensitivity: 'base' });
    return customSortFunction ? customSortFunction(ascending) : defaultSort;
};

/**
 * Generates a auxiliary component used to render custom wrappers, when passed to GenericDataTable component
 *
 * @param {Component} Wrapper Component used to wrap received data
 * @param {Object} data Contains row data
 * @param {String} column The respective data that needs to be rendered, depending on the column
 * @returns {Component} the Wrapper component, receive the whole row data as a prop
 */
const RowWrapper = ({ wrapper: Wrapper, data, column }) => {
    return <Wrapper data={data}>{data[column]}</Wrapper>;
};

/**
 * Returns a table component using Mantine base table component.
 * This component works as a base for more complex tables that require more fucntionalities, such as filtering and text search.
 *
 * Currently this component only supports:
 *      - Row selection
 *      - Pagination/ScrollY (pagination fixed with 5 rows per page)
 *      - Sorting
 *
 * Attention: data must be formatted before input and be consistent with column names definitions. Otherwise, component will not work properly.
 *
 * Patterns examples:
 *      columnsObject: {
 *          column: 'columnKey1',
 *          ascending: true
 *      },
 *
 *      dataObject: {
 *          columnKey1: "string1",
 *          columnKey2: "string2",
 *          (...)
 *          columnKeyN: "stringN",
 *      }
 *
 *      initialOrderObject: {
 *          column: 'keyX', ascending: false
 *      }
 *
 *
 *      rowActionObject: {
 *          label: "Row Group Example",
 *          actions: [
 *              {
 *                  label: "Do Something with row",
 *                  icon: <TbExternalLink/>,  // optional
 *                  callback: (singleSeriesId, singleSeriesRow) => console.log(singleSeriesId, singleSeriesRow),
 *              }
 *          ]
 *      }
 *
 *      selectedRows: {
 *          'df9a-8d7fa0-dss7-bvf0a-sd8gf': {
 *              columnKey1: 'Example data1',
 *              columnKey2: 'Example data2',
 *              (...)
 *          },
 *          (...)
 *      }
 *
 * @param {Array<Object>} column Each element need to be an object with definitions (name, identification key...) to render column. Patterns are described above.
 * @param {Array<Object>} data Each element need to be an object with the data to be displayed. As shown in the above pattern, each data object key need to match with column specified key. This means that data with wrong specified keys will NOT be displayed in table. Also, all object values MUST be string or numerical, otherwise data will not be displayed correctly.
 * @param {Array<Object>} rowActions Each element need to be an object with strings that represents group label, and this object needs to have an object as attribute, specifying the actions for the defined group.
 * @param {Boolean} noActionMenu Indicates if rowActions need to be rendered inside a context menu or in individual icon. It is recommended to always use context menu rendering.
 * @param {Boolean} noActionGroup Indicates if actions need to be rendered in groups. This is ignored if noActionMenu is provided, resulting in not separating actions in groups.
 * @param {Boolean} selectableRows Indicates if selection is enabled
 * @param {Boolean} paginate Indicates if pagination is enabled
 * @param {string} paginationPosition Indicates where the pagination menu will be placed, could be 'bottom', "top" or "both". Value is 'bottom' be default.
 * @param {Object} initialOrder Defines initial order to display data. If not provided, table will start with ascending order by first column
 * @param {Boolean} loading Indicates if data is loading yet or not. Ideally, this prop would be a state inside parent component
 * @param {string} loadingLabel Indicates the label that will be displayed while loading is true
 * @param {Object} selectedRows Object where each key is the selected row. This must be given if selectableRows is enabled, otherwise component will not work. Also, this must be an state managed in the parent component. In that way, GenericDataTable only manages its father state related to selectedRows.
 * @param {Function} changeSelectedRows This function is executed everytime a row is selected, in any way. It receives a object containing the selected rows, which means it can be overwritten and incrementented/decremented
 * @param {Boolean} fixedHeight Indicates if table must have a fixed height, i.e., having a infinite scroll effect, even if pagination is enabled.
 * @param {Boolean} highlightOnHover Indicates if table should highlight on hover
 * @param {Boolean} striped Indicates if table should have striped rows
 * @param {Component} Icon Component to be used in ordering. It is a single icon because its rotated, cannonically signing descendent order. Note this before inputing the component (note: this is not implemented yet)
 * @param {Boolean} selectOnRight Indicates if selection must stay in the right. In that case, actions stay at its left
 * @param {Integer} maxSelections Indicates number of maximum rows to be selected (default is 0, which means any)
 * @param {Boolean} radioLook Indicates if selection must look like a radio select (static only - set maxSelection to 1 to make a real radio select)
 * @param {Boolean} disableSelectAll Turns select all option off
 * @param {Boolean} stickyFirstColumn Indicates if firts column is sticky
 * @param {Boolean} sortableDataColumns Firts column is alway sortable. sortableDataColumns indicates if the rest of the columns are sortable
 * @param {Boolean} noRecordsMessage Optional message to be shown when table has no rows
 * @param {Function} onRowDoubleClick Optional function to be executed when user on double click in a table body row. The function receives the row data as first argument.
 * @param {Boolean} disableAllSelects Turns all ContainedBox option off
 *
 * @returns
 */
const GenericDataTable = ({
    columns,
    data,
    rowActions,
    noActionMenu,
    noActionGroup,
    selectableRows = false,
    paginate = true,
    paginationPosition = 'bottom',
    initialOrder,
    loading,
    loadingLabel,
    tableHeight,
    selectedRows = [],
    changeSelectedRows,
    initialPerPage,
    fixedHeight,
    style, // Optional styles
    highlightOnHover = true,
    striped,
    orderingIcon,
    fontSize,
    verticalSpacing,
    selectOnRight,
    maxSelections = 0,
    radioLook,
    disableSelectAll,
    stickyFirstColumn = false,
    sortableDataColumns = true,
    noRecordsMessage,
    onRowDoubleClick,
    disableAllSelects
}) => {
    const mantineTheme = useMantineTheme();
    const [displayData, setDisplayData] = useState(data);
    const [sortAscending, setSortAscending] = useState(
        initialOrder ? initialOrder.ascending : true
    );
    const [sortColumn, setSortColumn] = useState(
        initialOrder ? initialOrder.column : columns[0].key
    );
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(initialPerPage ? initialPerPage : 20);
    const [scrolled, setScrolled] = useState(false);
    const [lastSelected, setLastSelected] = useState('');

    const { classes, cx, theme } = useStyles();

    /**
     * Define sort options based on input options
     *
     * @param {String} column which data will be used to order rows
     */
    const defineSort = (key) => {
        if (sortColumn === key) {
            setSortAscending(!sortAscending);
        }
        setSortColumn(key);
    };

    /**
     * Row selection management functions
     */
    const clearSelection = () => {
        changeSelectedRows({});
    };

    const selectRow = (rowId, rowInfo) => {
        changeSelectedRows({ ...selectedRows, [`${rowId}`]: rowInfo });
        let lastSelectedIndex = Object.keys(selectedRows).length - 1;

        setLastSelected(Object.keys(selectedRows)[lastSelectedIndex]);
    };

    const desselectRow = (rowId) => {
        let newSelection = { ...selectedRows };
        delete newSelection[rowId];
        changeSelectedRows(newSelection);
    };

    const selectAll = () => {
        let selection = {};
        data.map((data) => (selection[data.id] = data));
        changeSelectedRows(selection);
    };

    /**
     * Display data setup whenever:
     *      - data changes
     *      - sort options changes
     *      - page or page options changes
     */
    useEffect(() => {
        // Get data
        let newDisplayData = [...data];

        // Sort that data
        newDisplayData = newDisplayData.sort(
            generateSort(
                sortColumn,
                sortAscending,
                columns.find((column) => column.key === sortColumn).sortFunction
            )
        );

        // If asked, separate that data into sections, based on the amount of rows per section desired
        if (paginate) {
            newDisplayData = newDisplayData.slice(
                (page - 1) * perPage,
                (page - 1) * perPage + perPage
            );
        }

        // Set the displayed data as the result
        setDisplayData(newDisplayData);
    }, [data, sortAscending, sortColumn, page, perPage]);

    useEffect(() => {
        if (selectableRows) {
            if (Object.keys(selectedRows).length > maxSelections && maxSelections > 0) {
                desselectRow(lastSelected);
            }
        }
    }, [Object.keys(selectedRows).length]);

    /**
     * Column components setup
     */
    const columnKeys = []; // holds columns names only. it is used to access received data for each column, so the names between columns and data must be consistent (case ignored)
    const columnWrappers = {};
    const columnElements = [];

    columns.map((column, i) => {
        if (column.wrapper) {
            columnWrappers[column.key] = column.wrapper;
        }

        columnKeys.push(column.key); // key is used to access data attribute. name is used to display
        stickyFirstColumn && i == 0
            ? columnElements.push(
                  <th
                      key={column.key}
                      className={cx(classes.stickyColumn, classes.headerCell, {
                          [classes.scrolled]: scrolled
                      })}
                      onClick={() => {
                          defineSort(column.key);
                      }}
                      style={{
                          paddingTop: 0,
                          paddingBottom: 0
                      }}>
                      <Box
                          sx={(theme) => ({
                              display: 'flex',
                              gap: 10,
                              alignItems: 'center',
                              transition: 'all 200ms ease',
                              color: theme.colors.gray[1],
                              fontWeight: 500
                          })}>
                          {column.name}
                          {orderingIcon ? (
                              <orderingIcon size={theme.spacing.lg} />
                          ) : (
                              <TbChevronDown
                                  size={theme.spacing.lg}
                                  style={{
                                      transition: '150ms',
                                      opacity: sortColumn === column.key ? 1 : 0,
                                      transform: sortAscending ? 'none' : 'rotate(-180deg)'
                                  }}
                              />
                          )}
                      </Box>
                  </th>
              )
            : columnElements.push(
                  <th
                      key={column.key}
                      className={
                          sortableDataColumns ? classes.headerCell : classes.noSortingHeaderCell
                      }
                      onClick={() => {
                          if (sortableDataColumns || i == 0) defineSort(column.key);
                      }}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0
                      }}>
                      <Box
                          sx={(theme) => ({
                              display: 'flex',
                              gap: 10,
                              alignItems: 'center',
                              transition: 'all 200ms ease',
                              color: theme.colors.gray[1],
                              fontWeight: 500
                          })}>
                          {column.name}
                          {orderingIcon ? (
                              <orderingIcon size={theme.spacing.lg} />
                          ) : (
                              <TbChevronDown
                                  size={theme.spacing.lg}
                                  style={{
                                      transition: '150ms',
                                      opacity: sortColumn === column.key ? 1 : 0,
                                      transform: sortAscending ? 'none' : 'rotate(-180deg)'
                                  }}
                              />
                          )}
                      </Box>
                  </th>
              );
    });

    if (Array.isArray(rowActions)) columnElements.push(<th key="actionMenu"></th>);

    const selectComponent = !disableSelectAll ? (
        <th
            style={{
                paddingLeft: selectOnRight ? 0 : mantineTheme.spacing.lg,
                paddingRight: selectOnRight ? mantineTheme.spacing.lg : 0,
                paddingTop: mantineTheme.spacing.lg
            }}
            key="selectAll">
            <ContainedCheckBox
                key="selectAll"
                color="orange"
                radioLook={radioLook}
                checked={data.length > 0 ? Object.keys(selectedRows).length === data.length : false}
                onChange={(event) => (event.target.checked ? selectAll() : clearSelection())}
            />
        </th>
    ) : (
        <th key="noSelect"></th>
    );

    if (selectableRows)
        selectOnRight
            ? columnElements.push(selectComponent)
            : columnElements.unshift(selectComponent); // if selectOnRight, selection will be placed in the end of the array. Otherwise, is placed in the start

    /**
     * Row components setup
     */
    const rows = displayData.map((data, index) => {
        let cells = [];
        const selectComponent = !disableAllSelects ? (
            <td
                style={{
                    paddingLeft: selectOnRight ? 0 : mantineTheme.spacing.lg,
                    paddingRight: selectOnRight ? mantineTheme.spacing.lg : 0
                }}
                key={`Check${index}`}>
                <ContainedCheckBox
                    color="orange"
                    radioLook={radioLook}
                    onChange={(event) =>
                        event.target.checked ? selectRow(data.id, data) : desselectRow(data.id)
                    }
                    checked={selectedRows[data.id] ? true : false}
                />
            </td>
        ) : (
            <td key="noSelect"></td>
        );

        columnKeys.map((key, i) => {
            stickyFirstColumn && i == 0
                ? cells.push(
                      <td
                          style={{
                              fontFamily: mantineTheme.other.secondaryFontFamily,
                              fontSize: '14px',
                              fontWeight: 500,
                              color: theme.colors.blue[0]
                          }}
                          key={i}
                          className={cx(classes.stickyColumn, { [classes.scrolled]: scrolled })}>
                          {columnWrappers[key] ? (
                              <RowWrapper wrapper={columnWrappers[key]} data={data} column={key} />
                          ) : (
                              data[0]
                          )}
                      </td>
                  )
                : cells.push(
                      <td
                          style={{
                              fontFamily: mantineTheme.other.secondaryFontFamily,
                              fontSize: '14px',
                              fontWeight: 700,
                              color: theme.colors.blue[0]
                          }}
                          key={i}>
                          {columnWrappers[key] ? (
                              <RowWrapper wrapper={columnWrappers[key]} data={data} column={key} />
                          ) : (
                              <Text size={14} weight={500} ff={theme.other.secondaryFontFamily}>
                                  {data[key]}
                              </Text>
                          )}
                      </td>
                  );
        });

        const actionMenuItems = []; // holds all actionMenu for each row. if groups are not provided, they are rendered as individual icons in the cell
        // the use of groups is implicitly denoted with the presence or not of the attribute actions inside each element of rowActions

        if (Array.isArray(rowActions)) {
            rowActions.map((group, index, array) => {
                let actions = group.actions;

                if (noActionMenu) {
                    // NOTE: groups are ignored if action menu is not being rendered

                    actions.map((action, i) => {
                        actionMenuItems.push(
                            <Tooltip
                                position="top"
                                label={action.label}
                                arrowSize={6}
                                openDelay={500}
                                key={`actionIcon${action.label}${i}`}>
                                <Box
                                    sx={(theme) => ({
                                        transition: '150ms',
                                        color: theme.colors.gray[7],
                                        '&:hover': {
                                            transform: 'scale(1.3)',
                                            cursor: 'pointer',
                                            color: action.color
                                                ? action.color
                                                : theme.colors.gray[8]
                                        },
                                        display: 'flex'
                                    })}
                                    onClick={() => action.callback(data.id, data)}
                                    disabled={action.disabled ? action.disabled : false}>
                                    {action.wrapper
                                        ? action.wrapper(
                                              data,
                                              React.cloneElement(action.icon, {
                                                  color: theme.colors.blue[0]
                                              })
                                          )
                                        : React.cloneElement(action.icon, {
                                              color: theme.colors.blue[0]
                                          })}
                                </Box>
                            </Tooltip>
                        );
                    });
                } else {
                    if (!noActionGroup) {
                        actionMenuItems.push(
                            <Menu.Label key={`MenuLabel${group.label}`}>{group.label}</Menu.Label>
                        );
                    }

                    actions.map((action) => {
                        actionMenuItems.push(
                            <Menu.Item
                                key={`MenuItem${action.label}`}
                                icon={action.icon}
                                disabled={action.disabled ? action.disabled(data) : false}
                                color={action.color}
                                onClick={() => action.callback(data.id, data)}>
                                {action.label}
                            </Menu.Item>
                        );
                    });

                    if (array.length - 1 !== index) {
                        actionMenuItems.push(<Menu.Divider key={`Divider${group.label}`} />);
                    }
                }
            });

            cells.push(
                <td key={`Action${index}`}>
                    {noActionMenu ? (
                        <Box
                            sx={(theme) => ({
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                gap: theme.spacing.md
                            })}>
                            {actionMenuItems}
                        </Box>
                    ) : (
                        <Menu shadow={'md'} width={200}>
                            <Menu.Target>
                                <ActionIcon>
                                    <TbDots color={'#092c4c'} />
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>{actionMenuItems}</Menu.Dropdown>
                        </Menu>
                    )}
                </td>
            );
        }

        if (selectableRows)
            selectOnRight ? cells.push(selectComponent) : cells.unshift(selectComponent); // if selectOnRight, selection will be placed in the end of the array. Otherwise, is placed in the start

        return (
            <tr
                key={`Row${index}`}
                className={'tableBodyRow'}
                onDoubleClick={(event) =>
                    event.target.tagName === 'TD' && onRowDoubleClick
                        ? onRowDoubleClick(data)
                        : undefined
                }>
                {cells}
            </tr>
        );
    });

    if (displayData.length <= 0) {
        let increment = 0;

        if (rowActions) increment += 1;
        if (selectableRows) increment += 1;

        rows.push(
            <tr key={`RowEmpty`}>
                <td
                    colSpan={columns.length + increment}
                    style={{ textAlign: 'center', padding: 20 }}
                    key="x">
                    {noRecordsMessage ? noRecordsMessage : 'Nenhuma linha encontrada'}
                </td>
            </tr>
        );
    }

    const PaginationMenu = () => {
        return (
            <>
                {paginate && <Divider my="sm" />}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: theme.spacing.md,
                        gap: theme.spacing.xl,
                        flexWrap: 'wrap'
                    }}>
                    {selectableRows && (
                        <Text size="sm" color={theme.colors.gray[0]}>
                            {Object.keys(selectedRows).length !== 0 &&
                                `${Object.keys(selectedRows).length} linha${
                                    Object.keys(selectedRows).length > 1 ? 's' : ''
                                } selecionada${Object.keys(selectedRows).length > 1 ? 's' : ''}`}
                        </Text>
                    )}
                    {paginate && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap: theme.spacing.md
                            }}>
                            <Text size="sm" color={theme.colors.gray[0]}>
                                Mostrando {displayData.length} de {data.length} linhas
                            </Text>
                            <Pagination
                                total={Math.ceil(data.length / perPage)}
                                onChange={setPage}
                                page={page}
                                color="orange"
                            />
                        </div>
                    )}
                    {paginate && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap: theme.spacing.md
                            }}>
                            <Text size="sm" color={theme.colors.gray[0]}>
                                Linhas por pagina
                            </Text>
                            <Select
                                data={['10', '20', '50', '100']}
                                placeholder={perPage.toString()}
                                value={perPage.toString()}
                                onChange={(value) => {
                                    setPerPage(parseInt(value));
                                    setPage(1);
                                }}
                            />
                        </div>
                    )}
                </div>
                {paginate && <Divider my="sm" />}
            </>
        );
    };

    // Scroll with autosize is breaking table when any row action menu is activated. not using till its fixed, so a whitespace below the rows is shown
    // when number of rows dont fill the pre defined height
    return (
        <>
            {(paginationPosition === 'top' || paginationPosition == 'both') &&
                data.length > 0 &&
                displayData.length > 0 && <PaginationMenu />}

            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 200
                    }}>
                    <h2>{loadingLabel}</h2>
                    <InfinitySpin
                        type="Oval"
                        color="rgba(0, 11, 67, 1)"
                        height={40}
                        width={40}
                        style={{ marginLeft: '20', marginBottom: '30' }}
                    />
                </div>
            ) : (
                <Box
                    sx={(theme) => ({
                        overflowX: 'auto',
                        borderBottom: '1px solid',
                        borderBottomColor: theme.colors.gray[2],
                        marginTop: theme.spacing.lg,
                        marginBottom: theme.spacing.lg,
                        backgroundColor: theme.colors.white,
                        borderRadius: theme.radius.md
                    })}>
                    {!paginate || fixedHeight ? (
                        <ScrollArea
                            scrollbarSize={10}
                            sx={{ height: tableHeight }}
                            onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
                            type={selectOnRight ? 'auto' : 'always'}>
                            <Table
                                highlightOnHover={highlightOnHover}
                                striped={striped}
                                fontSize="lg"
                                verticalSpacing={'md'}
                                sx={(theme) => ({
                                    // minWidth: 700,
                                })}>
                                <thead
                                    className={cx(classes.header, {
                                        [classes.scrolled]: scrolled
                                    })}>
                                    <tr>{columnElements}</tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </Table>
                        </ScrollArea>
                    ) : (
                        <Table
                            highlightOnHover={highlightOnHover}
                            striped={striped}
                            fontSize={fontSize ? fontSize : 'lg'}
                            verticalSpacing={verticalSpacing ? verticalSpacing : 'sm'}>
                            <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
                                <tr>{columnElements}</tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    )}
                </Box>
            )}

            {(paginationPosition === 'bottom' || paginationPosition == 'both') &&
                data.length > 0 &&
                displayData.length > 0 && <PaginationMenu />}
        </>
    );
};

export default GenericDataTable;
