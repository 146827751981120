import GenericDataTable from "../UI/GenericDataTable";
import { LampsIndexPlot } from "../plot/LampsIndexPlot"
import { Divider, Switch, Text, Select, Button, MultiSelect, Image, ScrollArea, Card, SegmentedControl, Tabs, useMantineTheme } from '@mantine/core';


export const LpgiIndexResultsSection = ({
    data,
    useStyles
}) => {
    const { classes } = useStyles({ width: 400 });

    const columns = [
        {
            name: 'Usina',
            key: 'name'
        },
        {
            name: 'Wppi index',
            key: 'index',
            sortFunction: (ascending) => {
                const newSort = (a, b) =>
                
                    ascending
                        ? a.index < b.index
                            ? -1
                            : 1
                        : a.index > b.index
                        ? -1
                        : 1
                return newSort;
            }
        }
    ]

    const tableData = Object.keys(data.index.individual_physical_guarantee).map((key) => ({
        name: key,
        index: data.index.individual_physical_guarantee[key]
    }))

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', gap: 5, marginBottom: 15 }}>
                <Text className={classes.LabelText} >Índice: </Text>
                <Text className={classes.DataText} >{data.index.index_type}</Text>
            </div>
            <Text className={classes.LabelText} >Usinas: </Text>
            <ul style={{ marginTop: 5, marginBottom: 15 }}>
                {data.index?.success_names.map((name, index) => {
                    return (
                        <li key={index}>
                            <Text className={classes.DataText} >{name}</Text>
                        </li>
                    )
                })}
            </ul>

           
            <Divider style={{ margin: '15px 0px 15px 0px' }} />
            <Text className={classes.LabelText} style={{marginTop: 20}} >{`Lpgi index:`}</Text>
            <LampsIndexPlot
                yAxisLabel={'MW'}
                data={data}
                useStyles={useStyles}
            />
          
        </>
    )
}