import React, { useState, useEffect, useRef } from "react";
import { Divider, Switch, Text, Select, Button, MultiSelect, Image, ScrollArea, Card, SegmentedControl, Tabs } from '@mantine/core';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import moment from "moment/moment";
import GeneralLinePlot from "./GeneralLinePlot";
import Info from "../UI/Info";
import { Oval } from "react-loader-spinner";

export const LampsIndexPlot = ({
    useStyles,
    data,
    type,
    yAxisLabel = null
}) => {
    const { classes } = useStyles();
    const [renderChart, setRenderChart] = useState(false)
    const echartsRef = useRef()

    useEffect(() => {
        setRenderChart(true)
    }, [])

    function generateCoverageIndexDataInfo(data) {
        let dataInfo = []
        let historicSeries = []
        for (let i = 0; i < data.historical.dates.length; i++) {
            historicSeries.push({ timestamp: data.index.historical_dates[i], value: data.index.aggregated_historical[i] })
        }
        dataInfo.push({
            preset: 'agregatedHistoric',
            series: historicSeries,
            
            style: data.index.min_demand_threshold ? {
                markLine: {
                    symbol: ['none', 'none'],
                    label: 'MW',
                    data: [
                        {
                            yAxis: data.index.min_demand_threshold,
                            label: {
                                color: 'rgba(254,121,56,1)'
                            },
                            lineStyle: {
                                color: 'rgba(254,121,56,1)'
                            }
                        }
                    ]
                }
            } : null
        })
        return dataInfo
    }

    function generateWppiIndexDataInfo(data, plotType) {
        let dataInfo = []
        let historicSeries = []
        let indexSeries = []

        if (plotType === 'historicWppi') {
            for (let i = 0; i < data.historical.dates.length; i++) {
                historicSeries.push({ timestamp: data.index.historical_dates[i], value: data.index.aggregated_historical[i] })
            }

            dataInfo.push({
                preset: 'agregatedHistoric',
                series: historicSeries,
                style: data.index.aggregated_physical_guarantee ? {
                    markLine: {
                        symbol: ['none', 'none'],
                        label: {
                            show: true,
                            formatter: '{c} (apgi)'
                        },
                        data: [
                            {
                                yAxis: data.index.aggregated_physical_guarantee,
                                label: {
                                    color: 'rgba(254,121,56,1)'
                                },
                                lineStyle: {
                                    color: 'rgba(254,121,56,1)'
                                }
                            }
                        ]
                    }
                } : null
            })

            return dataInfo
        }

        if (plotType === 'indexWppi') {

            for (let i = 0; i < data.index.index_dates.length; i++) {
                indexSeries.push({ timestamp: data.index.index_dates[i], value: data.index.wppi_index[i] })
            }
    
        
            dataInfo.push({
                preset: 'index',
                series: indexSeries,
                style: {
                    name:'Wppi Index',
                    id: 'Wppi Index',
                    markLine: {
                        symbol: ['none', 'none'],
                        label: 'MW',
                        data: [
                            {
                                yAxis: 1,
                                label: {
                                    color: 'rgba(254,121,56,1)'
                                },
                                lineStyle: {
                                    color: 'rgba(254,121,56,1)'
                                }
                            }
                        ]
                    }
                }
            })
    
            return dataInfo
        }

    }

    function generateLpgiIndexDataInfo(data) {
        let dataInfo = []
        let historicSeries = []
        let indexSeries = []
        for (let i = 0; i < data.historical.dates.length; i++) {
            historicSeries.push({ timestamp: data.index.historical_dates[i], value: data.index.aggregated_historical[i] })
        }

        for (let i = 0; i < data.index.index_dates.length; i++) {
            indexSeries.push({ timestamp: data.index.index_dates[i], value: data.index.physical_guarantee_index[i] })
        }

        dataInfo.push({
            preset: 'agregatedHistoric',
            series: historicSeries,
        })

        dataInfo.push({
            preset: 'index',
            series: indexSeries,
            style: {
                name:'Lpgi Index',
                id: 'Lpgi Index',
                markLine: {
                    symbol: ['none', 'none'],
                    label: {
                        show: true,
                        formatter: '{c} (apg)'
                    },
                    data: [
                        {
                            yAxis: data.index.aggregated_physical_guarantee,
                            label: {
                                color: 'rgba(254,121,56,1)'
                            },
                            lineStyle: {
                                color: 'rgba(254,121,56,1)'
                            }
                        }
                    ]
                }
            }
        })

        

        return dataInfo
    }

    function generateDataInfo(data, type) {
        if (data.index.index_type === 'Coverage Index') {
            return generateCoverageIndexDataInfo(data)
        } else if (data.index.index_type === 'Wppi Index') {
            return generateWppiIndexDataInfo(data, type)
        }
        else if (data.index.index_type === 'Lamps Physical Guarantee Index') {
            return generateLpgiIndexDataInfo(data)
        }
    }

    const tooltipFormatter = function (params) {
        const tooltipStrings = params
            .map((obj) =>
                obj.value
                    ? `${obj.marker} ${obj.seriesId}: <strong>${obj.value[1]?.toLocaleString(
                        'pt-BR',
                        {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }
                    )}</strong><br />`
                    : ''
            )
            .join('');
        return `${moment(params[0].axisValue).format('lll')}<br />` + tooltipStrings;
    };

    function subtractMonths(date, months) {
        const dateCopy = new Date(date);
        dateCopy.setMonth(dateCopy.getMonth() - months);
        return dateCopy;
    }

    function addMonths(date, months) {
        const dateCopy = new Date(date);
        dateCopy.setMonth(dateCopy.getMonth() + months);
        return dateCopy;
    }

    function formatDate(d) {
        return [d.getFullYear(),
        (d.getMonth() + 1).padLeft(),
        d.getDate().padLeft(),
        ].join('-') + ' ' +
            [d.getHours().padLeft(),
            d.getMinutes().padLeft(),
            d.getSeconds().padLeft()].join(':');
    }
    function generateInicialZoom(data) {
        let initialValue = new Date(data.startDate)
        let finalValue = addMonths(initialValue, 1)

        let initialFormatted = formatDate(initialValue)
        let finalFormatted = formatDate(finalValue)

        return { startValue: initialFormatted, endValue: finalFormatted }
    }

    const customYaxis = yAxisLabel ? {
        type: 'value',
        axisLabel: {
            formatter: '{value} MW'
          }
        } : null

    const customGrid = {
         containLabel: true,

    }
    return (
        <>
            <div >

                <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                    <Switch
                        checked={renderChart}
                        label="Renderizar gráfico"
                        onChange={() => setRenderChart(!renderChart)}

                    />
                    <Info>
                        Renderizar gráficos com grandes quantidades de dados pode deixar a aplicação lenta. Desligue esta opção para melhorar enquanto navega por outras áreas da ferramenta.
                    </Info>
                </div>
                {renderChart ?
                    <GeneralLinePlot
                        customYaxis={customYaxis}
                        customGrid={customGrid}
                        chartsRef={echartsRef}
                        title={''}
                        dataInfo={generateDataInfo(data, type)}
                        initialZoom={data.source === 'FOTOVOLTAICA' ? generateInicialZoom(data) : null}
                        customTooltipFormatter={tooltipFormatter}
                    /> :
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Text className={classes.CardText}>Ative o switch para visualizar o gráfico</Text>
                    </div>
                }
            </div>
        </>
    )
}

