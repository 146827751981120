import React, { useState, useEffect } from "react";
import { Button, Modal,Text, Radio } from "@mantine/core";
import { getPowerPlantsSeriesCSV } from "../../apis/api";

export const DownloadPpSeries = ({ data, useStyles }) => {
    const { classes } = useStyles();
    const [openModal, setOpenModal] = useState(false)
    const [selectedSeries, setSelectedSeries] = useState({})
    const [downloadOptions, setDownloadOptions] = useState([])

    const namesDict = {
        'historical': 'Histórico',
        'simulation': 'Simulação',
        'aggregated_statistics': 'Estatísticas agregadas'
    }

    useEffect(() => {
        const options = generateDownloadOptions(data)
        setDownloadOptions(options)
        let auxDict = {}
        for (let i = 0; i < options.length; i++) {
            auxDict[options[i]] = false
        }
        setSelectedSeries(auxDict)
    },[])
    
    function generateDownloadOptions(data) {
        let options = []
        if (data.dataSearched.includes('historical')) {
            options.push('historical')
        }

        if (data.dataSearched.includes('simulation')) {
            options.push('simulation')
        }

        if (data.dataSearched.includes('aggregated_statistics')) {
            options.push('aggregated_statistics')
        }
      
        return options
    }

    function generateRadioButtons(data) {
        return (
        <>
          {downloadOptions.map((option, index) => 
          <Radio 
                key={index}
                disabled={option === 'aggregated_statistics'}
                checked={selectedSeries[option]} 
                onChange={() => {
                    let auxDict = {...selectedSeries}
                    auxDict[option] = !auxDict[option]
                    for (let key in auxDict) {
                        if (key !== option) {
                            auxDict[key] = false
                        }
                    }
                    setSelectedSeries(auxDict)
                }} label={namesDict[option]} />) } 
        </>
        )
    }

    function getCsv(selectedSeries) {
        let state = data.state
        let type = data.source
        let powerplant = data.target
        var d = new Date(data.startDate),
            startDateFormated = [d.getFullYear(),
            (d.getMonth() + 1).padLeft(),
            d.getDate().padLeft(),
            ].join('-') + ' ' +
                [d.getHours().padLeft(),
                d.getMinutes().padLeft(),
                d.getSeconds().padLeft()].join(':');
        if (selectedSeries['historical']) {
            getPowerPlantsSeriesCSV(state, type, powerplant, startDateFormated, 'historical', (response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'historical.csv');
                document.body.appendChild(link);
                link.click();
            }, 
            (error) => {console.log('error', error)})
        }

        if (selectedSeries['simulation']) {
            getPowerPlantsSeriesCSV(state, type, powerplant, startDateFormated, 'simulation', (response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'simulation.csv');
                document.body.appendChild(link);
                link.click();
            }, 
            (error) => {console.log('error', error)})
        }
    }
    
    return (
        <>
        <div>

        <Button
            onClick={() => setOpenModal(true)}
            className={classes.DownloadButton}
            variant="outline"
        >
            Baixar
        </Button>
        <Modal
            opened={openModal}
            onClose={() => setOpenModal(false)}
            title={<Text className={classes.CardHeader}>Download</Text>}
            size="md"
            >
            <Text className={classes.ModalText}>Selecione uma a série para fazer o download</Text>
            {generateRadioButtons(data)}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 15}}>
        <Button
            onClick={() => setOpenModal(false)}
            >
            Cancelar
            </Button>
            <Button
            onClick={() => getCsv(selectedSeries)}
            >
                Baixar
            </Button>

        </div>
            </Modal>
        </div>
       
        </>

    )
}