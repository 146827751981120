import axios from "axios";

const ibge = axios.create({
  baseURL: "https://servicodados.ibge.gov.br/api/",
});

export const getMalha = async (map, acronym, completion, errorOcurred) => {
  let malha;
  switch (map) {
    case "País":
      malha = "paises/BR?intrarregiao=UF";
      break;
    case "Estado":
      malha = `estados/${acronym}?intrarregiao=municipio`;
      break;
    case "Região":
      malha = `regioes/${acronym}?intrarregiao=UF`;
      break;
  }
  ibge
    .get(`/v3/malhas/${malha}&formato=application/vnd.geo+json`)
    .then((response) => {
      completion(response.data);
    })
    .catch((error) => errorOcurred(error));
};

export const getMalhaBySIN = async (SIN, completion, errorOcurred) => {
  ibge
    .get(
      `/v3/malhas/paises/BR?intrarregiao=UF&formato=application/vnd.geo+json`
    )
    .then((response) => {
      completion(response.data);
    })
    .catch((error) => errorOcurred(error));
};

export const getAllStates = async (acronym, completion, errorOcurred) => {
  if (acronym){
    ibge
      .get(`/v1/localidades/regioes/${acronym}/estados`)
      .then((response) => completion(response.data))
      .catch((error) => errorOcurred(error));
  }
  else {
    ibge
      .get(`/v1/localidades/estados`)
      .then((response) => completion(response.data))
      .catch((error) => errorOcurred(error));
  }
};

export default ibge;
