import React, {useEffect, useState} from "react";
import {ActionIcon, createStyles, Divider, getStylesRef} from "@mantine/core";
import { TbX } from "react-icons/tb";
import { Results } from "../Results";
const tabsStyles = createStyles((theme, _params, getRef) => ({
    content: {
        display: 'none',
    },

    contentActive: {
        display: 'block',
        backgroundColor: 'white',
    },
    spacerDiv:{
        flexGrow: 1,
        borderBottom: '1px solid darkgray',
    },

    divider:{
        ref: getStylesRef('divider'),
    },

    tab: {
        backgroundColor: 'white',
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        backgroundColor: '#f5f5f7',
        borderBottom: '1px solid darkgray',
        borderRadius: '7px 7px 0px 0px',
         
        '&:hover': {
            backgroundColor: '#CDCDCD',
        }
    },



    tabActive: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        border: '1px solid darkgray',
        backgroundColor: 'white',
        borderBottom: '1px solid transparent',
        borderRadius: '7px 7px 0px 0px',       
    }
,

    tabs:{
        display: 'flex',
        [`&:hover .${getStylesRef('divider')}`]: {
            visibility: 'hidden'
          },
        
    },

    container: {
        backgroundColor: 'white',
    }

    ,
    closeIcon: {
        '&:hover': {
            color: '#ff8ba0'
        }},
    
    tabText: {
        padding: '10px 10px 10px 10px',

        '&:hover': {
            cursor: 'pointer',
    }
    }
}
));


export const CustomTabs = ({
    searches,
    setSearches,
    useStyles,
}) => {
    const {classes} = tabsStyles();
    const [activeTab, setActiveTab] = useState(0);


    useEffect(() => {
        let latestSearchIndex = searches.length - 1
        if (latestSearchIndex >= 0) {
            setActiveTab(latestSearchIndex)
        }
    },[searches])

    function closeTab(index) {
        if (index <= activeTab) {
            setActiveTab(activeTab => activeTab - 1)
        }
        let auxArray = [...searches]
        auxArray.splice(index, 1)
        setSearches(auxArray)
    }


    return (
        <div className="container">
            <div className={classes.tabs}>
                {searches.map((item, index) => {
                    return (
                    
                        <div className={activeTab === index ? classes.tabActive : classes.tab} >
                                <div 
                                className={classes.tabText}
                                onClick={() => {setActiveTab(index)}}>
                            {searches[index].target.slice(0, searches[index].target.indexOf('/'))}
                                </div>
                        <ActionIcon

                        variant="transparent"
                        onClick={() => closeTab(index)}
                        >
                            <TbX className={classes.closeIcon}
                            
                            />
                        </ActionIcon>     
                        {!(activeTab === index+1 || activeTab === index || index === searches.length-1) &&
                        <Divider orientation="vertical" className={classes.divider} size='sm' style={{height: '50%', marginTop: 10, color: '#092c4c'}}/>
                        }            
                        </div>
                     
                    )
                })}
                <div className={classes.spacerDiv}>

                </div>
            </div>
            <div className="content">
            {searches.map((item, index) => {
                    return (
                        <div className={activeTab === index ? classes.contentActive : classes.content} onClick={() => setActiveTab(index)}>
                            <Results
                                useStyles={useStyles}
                                data = {searches[index]} 
                            />
                        </div>
                    )
                })}
            </div>

        </div>
    )
}