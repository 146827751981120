import { AppShell } from '@mantine/core';
import HomePage from './HomePage';
import Sidebar from '../components/SearchBar';


const Layout = () => {
    return (
        <>
         <AppShell
                fixed
                styles={(theme) => ({
                    main: {
                        transition: '200ms',
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 'calc(var(--mantine-navbar-width, 0px))',
                        backgroundColor: theme.colors.offWhite
                    },

                    navbar: {
                        paddingTop: '0px !important'
                    }
                })}
                //</>header={<NavbarHeader macroPollingInterval={macroPollingInterval} />}
                >
                    <HomePage />
                </AppShell>
        </>
    )
}

export default Layout;