import React, { useState, useEffect } from "react";
import { Navbar, createStyles, Divider, Switch, Text, Select, Button, Stack, NumberInput, Radio, Tabs, Checkbox, TextInput, Group, MultiSelect, Modal, useMantineTheme } from '@mantine/core';



export const LampsIndexModal = ({
    hasIndexData,
    indexDeviation,
    setIndexDeviation,
    setCoverage,
    coverage,
    useStyles,
    open,
    setOpen,
    allPowerPlantsDict,
    ppsToCompare,
    setPPsToCompare,
    indexes,
    setIndexes,
    indexOptions,
    addedTargetToComparisonRef
}) => {
    const { classes } = useStyles({ width: 400 });
    const theme = useMantineTheme();


    function handleConfirm() {
       setOpen(false)
    }
    return (
        <Modal
            opened={open}
            onClose={() => setOpen(false)}
            title={<Text className={classes.CardHeader} style={{ overflow: 'visible' }}>Índices LAMPS</Text>}
            size={700}
        >
            <MultiSelect
                searchable
                size='sm'
                data={Object.keys(allPowerPlantsDict)?.map((key) => {
                    return { label: allPowerPlantsDict[key].name, value: key, group: allPowerPlantsDict[key].state }
                })}
                value={ppsToCompare}
                onChange={(value) => {
                    setPPsToCompare(value)
                }}
                clearable
                label='Usinas'
                className={classes.transparentDisabled}
                styles={(theme) => ({
                    input: {
                        maxHeight: 80,
                        overflowY: 'auto'
                    }
                })}
            />

            <Text className={classes.CardText} style={{ fontSize: 14, marginTop: 15, color: 'gray' }}>Índices:</Text>
            <ul style={{ paddingLeft: 15, marginTop: 5, marginBottom: 5 }}>
                {
                    indexOptions.map((item, index) => {
                        return (
                            <Radio
                                label={item.label}
                                key={index}
                                checked={indexes[item.value]}
                                style={{ marginBottom: 3 }}
                                onChange={(event) => {
                                    let copy = { ...indexes };
                                    copy[item.value] = !copy[item.value];
                                    Object.keys(copy).map((key) => {
                                        if (key != item.value) {
                                            copy[key] = false
                                        }
                                    })
                                    setIndexes(copy);
                                }}

                            />
                        )
                    })
                }
            </ul>
            {
                indexes['Coverage Index'] &&
                <NumberInput
                label="min coverage"
                value={coverage}
                onChange={(value) => {
                    setCoverage(value)
                }}
                max={1}
                precision={2}
                step={0.1}
                min={0}
                style={{width: '40%', paddingLeft: 15}}
        />
            }
            
            <Divider style={{ marginTop: 15 }} />

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 30 }}>
                <Button
                    onClick={() => {
                        let copyIndexes = { ...indexes }
                        Object.keys(copyIndexes).map((key) => {
                            copyIndexes[key] = false
                        }
                        )
                        setIndexes(copyIndexes)

                        setPPsToCompare([addedTargetToComparisonRef.current.value])
                    }}
                >
                    Limpar
                </Button>
                <Button
                disabled={!hasIndexData()}
                onClick={()=>handleConfirm()}>
                    Salvar
                </Button>
            </div>

        </Modal>
    )
}
