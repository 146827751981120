import * as echarts from 'echarts';
import * as moment from 'moment';
import langPTBR from './langPTBR';

const isEndOfMonth = (date) => {
    return moment(date).endOf('month').format('YYYY-MM-DD') === date.format('YYYY-MM-DD');
};
export const getRange = (seriesArray) => {
    // só vai funcionar pra casos de granularidade diária ou maior
    let rangeStart = moment(seriesArray[0].seriesMeta.start_date, 'YYYY-MM-DD');
    let rangeEnd = moment(seriesArray[0].seriesMeta.end_date, 'YYYY-MM-DD');
    for (let index in seriesArray) {
        let start = moment(seriesArray[index].seriesMeta.start_date, 'YYYY-MM-DD');
        let end = moment(seriesArray[index].seriesMeta.end_date, 'YYYY-MM-DD');
        if (start < rangeStart) rangeStart = start;
        if (end > rangeEnd) rangeEnd = end;
    }
    const granularity =
        'granularity' in seriesArray[0].seriesMeta
            ? seriesArray[0].seriesMeta.granularity.granularity
            : 4;
    let timeUnit = 'd';
    switch (granularity) {
        case 2:
            timeUnit = 'd';
            break;
        case 3:
            timeUnit = 'w';
            break;
        case 4:
            timeUnit = 'M';
            break;
        case 5:
            timeUnit = 'y';
            break;
    }
    const delta = parseInt(
        'granularity' in seriesArray[0].seriesMeta
            ? seriesArray[0].seriesMeta.granularity.unit_delta
            : 1
    );
    const dateRange = [rangeStart.format('YYYY-MM-DD')];
    while (dateRange.slice(-1)[0] != rangeEnd.format('YYYY-MM-DD')) {
        let end = moment(dateRange.slice(-1)[0], 'YYYY-MM-DD').add(delta, timeUnit);
        // assumo que todas as séries do mesmo arquivo tem a mesma granularidade e delta
        // necessario para quando a serie mensal for apenas de final de mes
        // TODO: usar mapGranularityTimeFormat para pegar o formato correto de acordo com a granularidade
        // TODO: caso a serie mensal comece e termine em um mes cujo ultimo dia é 28, ele vai supor que é uma série do ultimo dia do mes,
        // mas existe a possibilidade de ser uma série mensal do dia 28.
        if (
            granularity === 4 &&
            isEndOfMonth(rangeStart) &&
            isEndOfMonth(rangeEnd) &&
            isEndOfMonth(moment(dateRange.slice(-1)[0], 'YYYY-MM-DD'))
        ) {
            dateRange.push(end.endOf('month').format('YYYY-MM-DD'));
        } else {
            dateRange.push(end.format('YYYY-MM-DD'));
        }
    }
    return dateRange;
};


export const mapGranularityName = (id) => {
    switch (id) {
        case 0:
            return 'Sub-horária';
        case 1:
            return 'Horária';
        case 2:
            return 'Diária';
        case 3:
            return 'Semanal';
        case 4:
            return 'Mensal';
        case 5:
            return 'Anual';
        default:
            return 'Outro';
    }
};

export const mapGranularityValueByName = (name) => {
    switch (name) {
        case 'Sub-horária':
            return 0;
        case 'Horária':
            return 1;
        case 'Diária':
            return 2;
        case 'Semanal':
            return 3;
        case 'Mensal':
            return 4;
        case 'Anual':
            return 5;
        default:
            throw new Error('Invalid granularity name');
    }
};

export const formatDateByGranularity = (id) => {
    switch (id) {
        case 0:
            return 'DD/MM/YYYY HH:mm';
        case 1:
            return 'DD/MM/YYYY HH';
        case 2:
            return 'DD/MM/YYYY';
        case 3:
            return 'DD/MM/YYYY';
        case 4:
            return 'MM/YYYY';
        case 5:
            return 'YYYY';
        default:
            return 'MM/YYYY';
    }
};

export const unitNameByGranularity = (id) => {
    switch (id) {
        case 0:
            return 'minute';
        case 1:
            return 'hour';
        case 2:
            return 'day';
        case 3:
            return 'week';
        case 4:
            return 'month';
        case 5:
            return 'year';
        default:
            return 'month';
    }
};

export const dateOptionsByName = (name) => {
    switch (name) {
        case 'Mensal':
            return langPTBR.time.month.map((month, idx) => {
                return { label: month, value: idx + 1 };
            });
        case 'Diária':
            return langPTBR.time.dayOfWeek.map((day, idx) => {
                return { label: day, value: idx + 1 };
            });
        default:
            throw new Error('Granularity not supported');
    }
};

export const dateOptionsByGranularity = (granularity) => {
    const name = mapGranularityName(granularity);
    return dateOptionsByName(name);
};

export const mapGranularityTimeFormat = (id) => {
    switch (id) {
        case 0:
            return 'lll';
        case 1:
            return 'lll';
        case 2:
            return 'DD/MM/YYYY';
        case 3:
            return 'DD/MM/YYYY';
        case 4:
            return 'MM/YYYY';
        case 5:
            return 'YYYY';
        default:
            return 'DD/MM/YYYY';
    }
};

export const dateToString = (granularity, date) => {
    const granularityFormat = formatDateByGranularity(granularity);
    return moment(date).format(granularityFormat);
};

export const stringToDate = (granularity, string) => {
    const granularityFormat = formatDateByGranularity(granularity);
    return moment(string, granularityFormat);
};

export const compareDates = (date1, date2, granularity = 5, isPrev = false) => {
    let dateAux = date1;
    if (isPrev) {
        const gran = granularity.granularity;
        const granDiff = parseInt(granularity.unit_delta);
        switch (gran) {
            case 5:
                dateAux = moment(date1).add(granDiff, 'years').toDate();
                break;
            case 4:
                dateAux = moment(date1).add(granDiff, 'months').toDate();
                break;
            case 1:
                dateAux = moment(date1).add(granDiff, 'hours').toDate();
                break;
            case 0:
                dateAux = moment(date1).add(granDiff, 'minutes').toDate();
                break;
            default:
                dateAux = moment(date1).add(granDiff, 'days').toDate();
                break;
        }
    }

    if (moment(dateAux).isBefore(date2)) return -1;
    if (moment(dateAux).isSame(date2)) return 0;
    if (moment(dateAux).isAfter(date2)) return 1;
};

export const dateDiff = (date1, date2, granularity = 4) => {
    if (granularity >= 5) return moment(date2).diff(moment(date1), 'years');
    else if (granularity === 4) return moment(date2).diff(moment(date1), 'months');
    else if (granularity === 3) return moment(date2).diff(moment(date1), 'weeks');
    else if (granularity === 2) return moment(date2).diff(moment(date1), 'days');
    return 1;
};

function timestamps_as_list(dict_series) {
    return dict_series.map((point) => (point ? point['timestamp'] : null));
}

function values_as_list(dict_series) {
    return dict_series.map((point) => (point ? point['value'] : null));
}

function series_as_list(dict_series) {
    return dict_series.map((point) => (point ? [point['timestamp'], point['value']] : null));
}

function series_as_dict(
    name,
    timestamps,
    values,
    convertValuesToString = false,
    granularity = null
) {
    // if granularity is not null, series_as_dict will use the granularity to construct the timestamps (and will keep any holes with null values)
    if (granularity !== null) {
        const meta = {
            granularity: granularity,
            start_date: timestamps[0],
            end_date: timestamps[timestamps.length - 1]
        };
        const filledTimestamps = getRange([{ seriesMeta: meta }]);

        const tsValDict = {};
        timestamps.map((ts, i) => {
            tsValDict[ts] = values[i];
        });

        return {
            name: name,
            series: filledTimestamps.map((ts) => {
                return {
                    timestamp: ts,
                    value:
                        tsValDict[ts] !== undefined && tsValDict[ts] !== null
                            ? convertValuesToString
                                ? tsValDict[ts].toLocaleString('pt-BR', {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2
                                  })
                                : tsValDict[ts]
                            : null
                };
            })
        };
    }

    return {
        name: name,
        series: timestamps.map((ts, i) => {
            return {
                timestamp: ts,
                value:
                    values[i] !== undefined || values[i] !== null
                        ? convertValuesToString
                            ? values[i].toLocaleString('pt-BR', {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2
                              })
                            : values[i]
                        : null
            };
        })
    };
}

function series_as_list_with_name(dict_series, name) {
    return dict_series.map((point) => (point ? [point['timestamp'], point['value'], name] : null));
}

function jsonDataToCSV(json_series, col_names) {
    const timestamps = timestamps_as_list(json_series[0]);
    const values = json_series.map((series) => values_as_list(series));
    const rows = timestamps.map((ts, index) => [ts, ...values.map((v) => v[index])]);
    const headers = ['timestamps', ...col_names];
    return [headers, ...rows];
}

const ChartTitle = (props) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', ...props.style }}>
        {props.title && (
            <h2 style={{ marginBottom: 0, marginTop: props.noMargin ? '0px' : '20px' }}>
                {props.title}
            </h2>
        )}
    </div>
);

const defaultOptions = (props) => {
    return {
        toolbox: {
            feature: {
                dataZoom: {
                    filterMode: 'none',
                    type: 'inside'
                },
                restore: {},
                saveAsImage: {}
            },
            right: 50
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: function (value) {
                    return value?.toLocaleString('pt-BR');
                }
            },
            scale: true
        },
        tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                return `${moment(params[0].axisValue).format('lll')}<br />
                        ${params[0].marker} ${
                    params[0].seriesName
                }: <strong>${params[0].value[1]?.toLocaleString('pt-BR', {
                    maximumFractionDigits: 2
                })}</strong>`;
            }
        },
        xAxis: {
            type: 'time',
            boundaryGap: false
        },
        series: props.series
            ? [
                  {
                      data: series_as_list(props.series),
                      type: 'line',
                      connectNulls: props.connectNulls ? props.connectNulls : false,
                      showSymbol: props.showSymbol ? props.showSymbol : false,
                      lineStyle: {
                          width: 1
                      },
                      emphasis: {
                          lineStyle: {
                              width: 1
                          }
                      }
                  }
              ]
            : []
    };
};

export {
    timestamps_as_list,
    values_as_list,
    series_as_list,
    series_as_dict,
    series_as_list_with_name,
    jsonDataToCSV,
    defaultOptions,
    ChartTitle
};
