import React, { useState, useEffect } from "react";
import GenericDataTable from "./GenericDataTable";
import { Text } from '@mantine/core';

export const SolarPowerPlantSpreadsheet = ({
    useStyles,
    data,
    hasSimulation,
}) => {
    const  [selectedRows, setSelectedRows] = useState({})
    const [renderSheet, setRenderSheet] = useState(false)

    useEffect(()=>{
        setTimeout(()=>{
            setRenderSheet(true)
        }, 1000)
    },[hasSimulation])
   const columns = hasSimulation ? [
    {
        name: 'Nome',
        key: 'name',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Tipo',
        key: 'SigTipoGeracao',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }

    },
    {
        name: 'Capacidade instalada',
        key: 'capacidade_instalada',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Município',
        key: 'DscMuninicpios',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Fase',
        key: 'DscFaseUsina',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Início da operação',
        key: 'DatEntradaOperacao',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }

    },

    {
        name: 'Fim da vigência',
        key: 'DatFimVigencia',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Início da vigência',
        key: 'DatInicioVigencia',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Potência outorgada',
        key: 'MdaPotenciaOutorgadaKw',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },

    {
        name: 'Potência fiscalizada',
        key: 'MdaPotenciaFiscalizadaKw',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Potência garantia física',
        key: 'MdaGarantiaFisicaKw',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Origem do combustível',
        key: 'DscOrigemCombustivel',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Fonte do combustível',
        key: 'DscFonteCombustivel',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Sub-bacia',
        key: 'DscSubBacia',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Data de geração do conjunto de dados',
        key: 'DatGeracaoConjuntoDados',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
        
    {
        name: 'Proprietário',
        key: 'DscPropriRegimePariticipacao',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },

    {
        name: 'Tipo de outorga',
        key: 'DscTipoOutorga',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Núcleo CEG',
        key: 'IdeNucleoCEG',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Latidute',
        key: 'NumCoordNEmpreendimento',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Longitude',
        key: 'NumCoordEEmpreendimento',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    
    },
    {
        name: 'UF',
        key: 'SigUFPrincipal',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }

    },
    {
        name: 'Geração qualificada',
        key: 'IdcGeracaoQualificada',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
  {
        name: 'Sistema elétrico',
        key: 'sistemaEletrico',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
  }

   ] : [
    {
        name: 'Nome',
        key: 'name',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Modalidade',
        key: 'cod_modalidadeoperacao',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Tipo',
        key: 'nom_tipousina',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Combustível',
        key: 'nom_tipocombustivel',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }

    },
    {
        name: 'Estado',
        key: 'nom_estado',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Subsistema',
        key: 'nom_subsistema',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
    {
        name: 'Geração',
        key: 'val_geracao',
        wrapper: ({ children, data }) => {
            return <Text style={{width: 180}}>{children? children : '-'}</Text>
        }
    },
   

   ]


    const dataArray = hasSimulation ? Object.keys(data.meta.meta).map((key, index) => {
        if (key !== 'Sistema Elétrico') {
            return { ...data.meta.meta[key], name: data.meta.meta[key].NomEmpreendimento, sistemaEletrico: data.meta.meta['Sistema Elétrico'] }
        }
    })
    : [{...data.meta.meta, name: data.meta.meta.nom_usina}]

    return (
        <>
       {dataArray.length > 0 && renderSheet &&
       <div>
        
           <GenericDataTable
               columns={columns}
               data={dataArray.filter((item) => item !== undefined)}
               selectedRows={selectedRows}
               stickyFirstColumn
               changeSelectedRows={(rows) => setSelectedRows(rows)}
           />
       </div>
}
        </>
    )
}