import { BrowserRouter } from 'react-router-dom'
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';


const mantineTheme = {
  fontFamily: 'Raleway',
  colorScheme: 'light',
  colors: {
      blue: [
          '#092c4c',
          '#092c4c',
          '#092c4c',
          '#092c4c',
          '#092c4c',
          '#092c4c',
          '#092c4c',
          '#092c4c',
          '#092c4c',
          '#092c4c'
      ],
      offWhite: [
          '#f5f5f7',
          '#f5f5f7',
          '#f5f5f7',
          '#f5f5f7',
          '#f5f5f7',
          '#f5f5f7',
          '#f5f5f7',
          '#f5f5f7',
          '#f5f5f7',
          '#f5f5f7'
      ],
      lightBlue: [
          '#ebebf7',
          '#ebebf7',
          '#ebebf7',
          '#ebebf7',
          '#ebebf7',
          '#ebebf7',
          '#ebebf7',
          '#ebebf7',
          '#ebebf7',
          '#ebebf7'
      ],
      orange: [
          '#e56226',
          '#e56226',
          '#e56226',
          '#e56226',
          '#e56226',
          '#e56226',
          '#e56226',
          '#e56226',
          '#e56226',
          '#e56226'
      ],
      gray: [
          '#8d8d8d',
          '#a1a1aa',
          '#d0d0d0',
          '#e2e2e2',
          '#e2e2e2',
          '#e2e2e2',
          '#e2e2e2',
          '#e2e2e2',
          '#e2e2e2',
          '#5F5F5F'
      ],
      white: [
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff',
          '#ffffff'
      ]
  },
  primaryColor: 'blue',
  headings: {
      fontFamily: 'Raleway',
      fontWeight: 600,
      sizes: {
          h1: { fontSize: '52px' },
          h2: { fontSize: '32px' },
          h3: { fontSize: '26px' },
          h4: { fontSize: '24px' }
      }
  },
  radius: {
      xs: '2px',
      sm: '4px',
      md: '12px',
      lg: '20px'
  },
  other: {
      secondaryFontFamily: 'PublicSans',
      backgroundColor: 'offWhite',
      accentColor: 'lightBlue',
      highlightColor: 'orange'
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <BrowserRouter>
      <MantineProvider theme={mantineTheme}>
    <App />
    </MantineProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
