import React, { useEffect, useState } from "react";
import {
  Navbar,
  createStyles,
  Divider,
  Switch,
  Text,
  Select,
  Button,
  MultiSelect,
  Image,
  ScrollArea,
  Tabs,
} from "@mantine/core";
import SearchBar from "../components/SearchBar";
import logo from "../assets/images/logo.svg";
import { Results } from "../components/Results";
import { CustomTabs } from "../components/UI/CustomTabs";
import Map from "../components/plot/Map";

const useStyles = createStyles((theme, _params, getRef) => ({
  LabelText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: theme.colors.gray[1],
  },
  DataText: {
    fontFamily: theme.other.secundaryFontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: theme.colors.gray[9],
  },
  CardHeader: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.headings.fontWeight,
    fontSize: theme.headings.sizes.h4.fontSize,
    lineHeight: "28px",
    color: theme.colors.blue,
  },
  CardText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    marginRight: "5%",
    color: theme.colors.blue,
  },

  tooltip: {
    fontFamily: theme.other.secondaryFontFamily,
    fontSize: "14px",
    fontWeight: 500,
    color: theme.colors.gray[0],
  },

  configWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
    marginBottom: 15,
    marginTop: 15,
  },
}));

const HomePage = () => {
  const { classes } = useStyles();
  const [searches, setSearches] = useState([]);
  const [mapType, setMapType] = useState({map: "País", acronym: ""});
  const [mapPoints, setMapPoints] = useState([]);
  return (
    <>
      <SearchBar
        searches={searches}
        setSearches={setSearches}
        mapType={mapType}
        setMapType={setMapType}
        mapPoints={mapPoints}
        setMapPoints={setMapPoints}
      />
      <div style={{ height: "100%" }} className="LayoutContainer">
        {searches.length > 0 ? (
          <div>
            <CustomTabs
              searches={searches}
              useStyles={useStyles}
              setSearches={(arr) => setSearches(arr)}
            />
          </div>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image src={logo} alt="logo" width={290} />
            {/* <Map
              coordenadas={mapPoints}
              setCoordenadas={setMapPoints}
              mapType={mapType}
            ></Map> */}
          </div>
        )}
      </div>
    </>
  );
};

export default HomePage;
