import logo from './logo.svg';
import './App.css';
import Layout from './views/Layout';


function App() {
  return (
    <>
    <Layout />
    </>
  );
}

export default App;
