import React, { useState, useEffect } from "react";
import { MultiSelect, Select} from '@mantine/core';


export const StatesFilterComponent = ({
    useStyles,
    filters,
    setFilters,
    data 
}) => {
    const {classes} = useStyles({ width: null });
    let options = data.map((state) => ({label: state, value: state}))
    const [states, setStates] = useState(options[0]);

    useEffect(() => {
        let copyFilters = {...filters}
        copyFilters['location'] = states
        copyFilters['target'] = null
    }, [states])
    return (
        <>
        <Select
                        size='md'
                        data={options}
                        value={states}
                        onChange={(value) => {setStates(value)}}
                        label= {'Estado'}
                    />
        </>
    )
    
}