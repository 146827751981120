import GenericDataTable from "../UI/GenericDataTable";
import { LampsIndexPlot } from "../plot/LampsIndexPlot"
import { Divider, Switch, Text, Select, Button, MultiSelect, Image, ScrollArea, Card, SegmentedControl, Tabs, useMantineTheme } from '@mantine/core';


export const CoverageIndexResultsSection = ({
    data,
    useStyles
}) => {
    const { classes } = useStyles({ width: 400 });

    
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', gap: 5, marginBottom: 10 }}>
                <Text className={classes.LabelText} >Índice: </Text>
                <Text className={classes.DataText} >{data.index.index_type}</Text>
            </div>
            <Text className={classes.LabelText} >Usinas: </Text>
            <ul style={{ marginTop: 5, marginBottom: 10 }}>
                {data.index?.names_list.map((name, index) => {
                    return (
                        <li key={index}>
                            <Text className={classes.DataText} >{name}</Text>
                        </li>
                    )
                })}
            </ul>
            <Divider style={{ margin: '15px 0px 15px 0px' }} />

            <Text className={classes.LabelText} style={{marginTop: 20}} >{`${data.index.index_type}:`}</Text>
            <LampsIndexPlot
                data={data}
                useStyles={useStyles}
            />
        </>
   
    )
}